import React, {
  useContext, useRef
} from 'react'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Grid,
  makeStyles,
  FormControl,
  LinearProgress,
  Tooltip
} from '@material-ui/core'
import {
  ValidatorForm,
  TextValidator
} from 'react-material-ui-form-validator'
import {
  Autocomplete, Alert
} from '@material-ui/lab'
import InfoIcon from '@material-ui/icons/Info'
import queryString from 'query-string'
import cloneDeep from 'lodash/cloneDeep'
import RadioButtons from '../inputs/radio'
import requestAccess from '../../services/api/requestaccessService'
import CartService from '../../services/api/cartService'
import EnhancedTable from '../sections/enhancedTable'
import modelUserService from '../../services/api/modelUserService'
import UserContext from '../contexts/UserContext'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  formControl: {
    margin: theme.spacing(1),
    width: '98%'
  },
  footer: {
    margin: theme.spacing(2),
    float: 'right'
  },
  success: {
    backgroundColor: theme.palette.success.light
  },
  pending: {
    backgroundColor: '#fff9c4'
  },
  na: {
    backgroundColor: '#D3D3D3'
  },
  alertroot: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  }
}))

const headCells = [
  {
    id: 'group_name',
    label: 'Group'
  },
  {
    id: 'key_app_name',
    label: 'Project or Team'
  }
]

export default function ModelUserComponent (props) {
  const queryparams = queryString.parse(props.location.search)
  const [loading, setLoading] = React.useState(false)
  const [successAlert, setsuccessAlert] = React.useState(false)
  const [errorAlert, seterrorAlert] = React.useState(false)
  const [failalertMessage, setFailalertMessage] = React.useState(null)
  const [successalertMessage, setSuccessalertMessage] = React.useState(null)
  const [radiovalue, setRadioValue] = React.useState('Model a User')
  const [allUsers, setAllusers] = React.useState([])
  const [templates, setTemplates] = React.useState([])
  const [groups, setGroups] = React.useState([])
  const [groupscopy, setGroupsCopy] = React.useState([])
  const [loadingoptions, setLoadingoptions] = React.useState(false)
  const {
    user, setUser
  } = useContext(UserContext)
  const classes = useStyles()
  const form = useRef(null)

  // form data declaration
  const [formData, setFormData] = React.useState({
    usertomodel: '',
    requestedfor: '',
    usertomodeldisplay: '',
    requestedfordisplay: '',
    modeltemplate: '',
    templateid: '',
    comments: ''
  })
  /* to get the available tools for tool selection dropdown when the page gets loaded */
  React.useEffect(() => {
    if (queryparams.username) {
      requestAccess.getallusersforautocomplete(queryparams.username)
        .then((response) => {
          const filtervalue = response.data
            .filter((item) => item.name.toLowerCase() ===
            queryparams.username.toLowerCase())
          if (filtervalue.length && filtervalue.length === 1) {
            if (queryparams.req_for) {
              requestAccess.getallusersforautocomplete(queryparams.req_for)
                .then((response1) => {
                  const filtervalue1 = response1.data
                    .filter((item) => item.name.toLowerCase() ===
                    queryparams.req_for.toLowerCase())
                  if (filtervalue1.length && filtervalue1.length === 1) {
                    changeUser(filtervalue[0], 'usertomodel', filtervalue1[0])
                  }
                })
            } else {
              changeUser(filtervalue[0], 'usertomodel')
            }
          }
        })
    } else if (queryparams.template) {
      setRadioValue('Model based on Template')
      modelUserService.getModelTemplates(user.name).then((response) => {
        setTemplates(response.data)
        const filtervalue = response.data.filter((item) => item.name ===
          queryparams.template)
        if (filtervalue.length && filtervalue.length === 1) {
          if (queryparams.req_for) {
            requestAccess.getallusersforautocomplete(queryparams.req_for)
              .then((response1) => {
                const filtervalue1 = response1.data
                  .filter((item) => item.name.toLowerCase() ===
                  queryparams.req_for.toLowerCase())
                if (filtervalue1.length && filtervalue1.length === 1) {
                  changeTemplate(filtervalue[0], filtervalue1[0])
                }
              })
          } else {
            changeTemplate(filtervalue[0])
          }
        }
      })
    }
  }, [])
  /* function to get the users list based on the search key */
  const getUsers = (value) => {
    setLoadingoptions(true)
    requestAccess.getallusersforautocomplete(value).then((response) => {
      setAllusers(response.data)
      setLoadingoptions(false)
    })
  }
  /* to change radio option */
  const changeOption = (value) => {
    setRadioValue(value)
    resetForm()
    if (value === 'Model based on Template') {
      modelUserService.getModelTemplates(user.name).then((response) => {
        setTemplates(response.data)
      })
    }
  }
  /* funtion to get label with validation tooltip */
  function getTooltipLabel (value, message) {
    return (
      <div>
        {value}
        <Tooltip title={(
          <span style={{
            fontSize: 12,
            fontFamily: 'Verdana'
          }}
          >
            {message}
          </span>
        )}
        >
          <InfoIcon color='action' id='infoicon' fontSize='small' />
        </Tooltip>
      </div>
    )
  }
  /* function to set validation for all roles */
  function setValidationRoles (validationdata, username, groupdata) {
    setGroups([])
    const copydata = cloneDeep(groupdata)

    copydata.forEach((currentValue, idx) => {
      if (validationdata.length) {
        // validationdata having the roles with validation details (already access, present in cart, submitted for apporval)
        validationdata.forEach((validation) => {
          /* To check the groups data with validation data, if the group is matched with validation data then below conditions will
          set status and tooltip for all groups.
          so that in UI, the respective groups will show in different color with tooltip message based on the status
          */
          if (
            validation.already_access &&
            !currentValue.status &&
            currentValue.group_name.toLowerCase() ===
              validation.already_access.toLowerCase()
          ) {
            copydata[idx].status = 'success'
            copydata[idx].group_name = getTooltipLabel(currentValue.group_name,
              'Selected user already granted this role')
          } else if (
            validation.cart_requests &&
            !currentValue.status &&
            currentValue.group_name.toLowerCase() ===
              validation.cart_requests.toLowerCase()
          ) {
            copydata[idx].status = 'pending'
            copydata[idx].group_name = getTooltipLabel(currentValue.group_name,
              'Selected role with user already present in cart')
          } else if (
            validation.pending_requests &&
            !currentValue.status &&
            currentValue.group_name.toLowerCase() ===
              validation.pending_requests.toLowerCase()
          ) {
            copydata[idx].status = 'pending'
            copydata[idx].group_name = getTooltipLabel(currentValue.group_name,
              'Selected role with user is pending for approval')
          } else if (
            validation.processing_request &&
            !currentValue.status &&
            currentValue.group_name.toLowerCase() ===
              validation.processing_request.toLowerCase()
          ) {
            copydata[idx].status = 'pending'
            copydata[idx].group_name = getTooltipLabel(currentValue.group_name,
              'Selected role with user is processing in IDMS')
          } else if (
            validation.training_requests &&
            !currentValue.status &&
            currentValue.group_name.toLowerCase() ===
              validation.training_requests.toLowerCase()
          ) {
            copydata[idx].status = 'pending'
            copydata[idx].group_name = getTooltipLabel(currentValue.group_name,
              'Selected user with role is waiting for training check.')
          }
          if (
            currentValue.group_name &&
              currentValue.group_name.toString().toLowerCase()
                .indexOf('-qtest-') > -1
          ) {
            const validationProjectKey = currentValue.group_name.split('-')[2]
            if (
              (validation.cart_requests &&
                validation.cart_requests.toLowerCase()
                  .indexOf(validationProjectKey.toLowerCase()) > -1) ||
              (validation.pending_requests &&
                validation.pending_requests.toLowerCase()
                  .indexOf(validationProjectKey.toLowerCase()) > -1) ||
              (validation.already_access &&
                validation.already_access.toLowerCase()
                  .indexOf(validationProjectKey.toLowerCase()) > -1) ||
              (validation.processing_request &&
                validation.processing_request.toLowerCase()
                  .indexOf(validationProjectKey.toLowerCase()) > -1)
            ) {
              copydata[idx].status = 'na'
              copydata[idx].group_name = getTooltipLabel(currentValue
                .group_name, `For qTest project, 
                  user can be part of only one role in a project.`)
            }
          }
        })
      }
    })
    /* To check the user can have access to raise request for selected roles
using this API, we can get the DN for username from AD
and checking the user roles from AD is matching with ou_restrictions of particular role  */
    modelUserService.getUserEmployeeType(username).then((response) => {
      const userDN = response.data
      copydata.forEach((group, index) => {
        if (!group.status && userDN) {
          if (
            group.ou_restrictions && group.ou_restrictions.allow.length
          ) {
            let idx = -1
            group.ou_restrictions.allow.forEach((allow, i) => {
              if (userDN.indexOf(allow.ou) > -1) {
                idx = i
              }
            })

            if (idx === -1) {
              const userRole = userDN.split(',')
              let message = ''
              group.ou_restrictions.allow.forEach((allow) => {
                message = `${allow.ou}, `
              })
              copydata[index].status = 'disabled'
              copydata[index].group_name = getTooltipLabel(group.group_name,
                `Users part of any one of these Organization Unit (
                    ${message} ) can request for this role, 
                      This account is a member of ${userRole} 
                        Organization Unit `)
            }
          }
        }
      })
      setGroups(copydata)
    })
  }
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }
  /* function will be called when the user name selected from the options */
  const changeUser = (value, field, value1) => {
    if (value && value.name) {
      if (field === 'usertomodel') {
        setFormData({
          ...formData,
          usertomodel: value.name,
          usertomodeldisplay: `${value.name} - ${value.displayname}`,
          requestedfor: value1 ? value1.name : '',
          requestedfordisplay: value1
            ? `${value1.name} - ${value1.displayname}`
            : ''
        })
        setGroups([])
        modelUserService.getUserToModel({
          username: value.name
        }).then((response) => {
          setGroups(response.data)
          setGroupsCopy(cloneDeep(response.data))
          if (value1) {
            modelUserService.getUserRoleValidation(value1.name)
              .then((response1) => {
                setValidationRoles(response1.data, value1.name, response.data)
              })
          }
        })
      } else {
        setFormData({
          ...formData,
          requestedfor: value.name,
          requestedfordisplay: `${value.name} - ${value.displayname}`
        })
        modelUserService.getUserRoleValidation(value.name).then((response) => {
          setValidationRoles(response.data, value.name, groupscopy)
        })
      }
    }
  }
  /* function to get available templates while selecting template name */
  const changeTemplate = (value, value1) => {
    if (value && value.name) {
      setFormData({
        ...formData,
        modeltemplate: value.name,
        templateid: value.id,
        requestedfor: value1 ? value1.name : '',
        requestedfordisplay: value1
          ? `${value1.name} - ${value1.displayname}`
          : ''
      })
      setGroups([])
      modelUserService.getTemplateGroups({
        templateid: value.id
      }).then((response) => {
        setGroupsCopy(cloneDeep(response.data))
        setGroups(response.data)
        if (value1) {
          modelUserService.getUserRoleValidation(value1.name)
            .then((response1) => {
              setValidationRoles(response1.data, value1.name, response.data)
            })
        }
      })
    }
  }

  // submit function
  const handleSubmit = () => {
    const input = {
      username: user.name,
      requestedFor: formData.requestedfor,
      comments: formData.comments
    }
    if (radiovalue === 'Model a User') {
      input.modeluser = formData.usertomodel
    } else {
      input.templateid = formData.templateid.toString()
      input.templatename = formData.modeltemplate
    }
    setLoading(true)
    modelUserService.modelUserAddToCart(input).then((response) => {
      CartService.cartSize(user.name).then((cartdata) => {
        setUser({
          ...user,
          cartsize: cartdata.data.cartSize
        })
      })
      setLoading(false)
      if (response.data === 'success') {
        resetForm()
        setSuccessalertMessage('Added to cart successfully')
        setsuccessAlert(true)
        setTimeout(() => {
          setsuccessAlert(false)
        }, 20000)
      } else {
        setFailalertMessage(response.data)
        seterrorAlert(true)
      }
    },
    (error) => {
      setLoading(false)
      if (error) {
        setFailalertMessage(`
          Something went wrong. Contact system administrator!
        `)
        seterrorAlert(true)
      }
    })
  }
  /* reset form function */
  const resetForm = () => {
    setFormData({
      usertomodel: '',
      requestedfor: '',
      usertomodeldisplay: '',
      requestedfordisplay: '',
      modeltemplate: '',
      templateid: '',
      comments: ''
    })
    setGroups([])
    setGroupsCopy([])
    setAllusers([])
    seterrorAlert(false)
  }
  return (
    <div>
      {loading ? <LinearProgress /> : null}
      {' '}
      <div className={classes.alertroot}>
        {' '}
        {successAlert ? (
          <Alert severity='success'>{successalertMessage}</Alert>
        ) : null}
        {errorAlert ? (
          <Alert
            severity='error'
            onClose={() => seterrorAlert(false)}
          >
            {failalertMessage}
          </Alert>
        ) : null}
      </div>
      <Grid container spacing={1} className={classes.root}>
        <Grid item xs={12} md={5}>
          <Card variant='outlined'>
            <ValidatorForm ref={form} onSubmit={(event) => handleSubmit()}>
              <CardContent>
                <Typography variant='subtitle1' gutterBottom>
                  Model User Access
                </Typography>
                <div style={{
                  paddingTop: 10
                }}
                >
                  <RadioButtons
                    radio={['Model a User', 'Model based on Template']}
                    selectedvalue={radiovalue}
                    onChange={(value) => changeOption(value)}
                  />
                  {radiovalue === 'Model a User' ? (
                    <FormControl className={classes.formControl}>
                      <Autocomplete
                        id='combo-box-demo-modeluser'
                        options={allUsers}
                        value={formData.usertomodeldisplay}
                        getOptionLabel={(option) => (option.name
                          ? `${option.name} - ${option.displayname}`
                          : option)}
                        loading={
                          allUsers.length === 0 &&
                        loadingoptions
                        }
                        filterOptions={(options, state) => options}
                        getOptionSelected={
                          (option, value) => option.name === value.name
                        }
                        onChange={
                          (event, value) => changeUser(value, 'usertomodel')
                        }
                        renderInput={(params) => (
                          <TextValidator
                            {...params}
                            label='User to Model'
                            name='usertomodel'
                            onChange={(event) => getUsers(event.target.value)}
                            value={formData.usertomodeldisplay}
                            validators={['required']}
                            errorMessages={['This field is required']}
                          />
                        )}
                      />
                    </FormControl>
                  ) : (
                    <FormControl className={classes.formControl}>
                      <Autocomplete
                        id='combo-box-modeltemplate'
                        options={templates}
                        value={formData.modeltemplate}
                        getOptionLabel={(option) => (option.name
                          ? option.name
                          : option)}
                        getOptionSelected={
                          (option, value) => option.name === value.name
                        }
                        onChange={(event, value) => changeTemplate(value)}
                        renderInput={(params) => (
                          <TextValidator
                            {...params}
                            label='Template to Model'
                            name='modeltemplate'
                            value={formData.modeltemplate}
                            validators={['required']}
                            errorMessages={['This field is required']}
                          />
                        )}
                      />
                    </FormControl>
                  )}
                  <FormControl className={classes.formControl}>
                    <Autocomplete
                      id='combo-box-requestedfor'
                      options={allUsers}
                      value={formData.requestedfordisplay}
                      filterOptions={(options, state) => options}
                      getOptionLabel={(option) => (option.name
                        ? `${option.name} - ${option.displayname}`
                        : option)}
                      loading={
                        allUsers.length === 0 &&
                      loadingoptions
                      }
                      getOptionSelected={
                        (option, value) => option.name === value.name
                      }
                      onChange={
                        (event, value) => changeUser(value, 'requestedfor')
                      }
                      renderInput={(params) => (
                        <TextValidator
                          {...params}
                          label='Requested For'
                          name='requestedfor'
                          onChange={(event) => getUsers(event.target.value)}
                          value={formData.requestedfordisplay}
                          validators={['required']}
                          errorMessages={['This field is required']}
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextValidator
                      label='Reason for Requesting Access*'
                      name='comments'
                      onChange={handleChange}
                      value={formData.comments}
                      multiline
                      fullWidth
                      rows={1}
                      rowsMax={4}
                      validators={['required']}
                      errorMessages={['This field is required']}
                    />
                  </FormControl>
                </div>
              </CardContent>
              <CardActions className={classes.footer}>
                <Button
                  variant='contained'
                  size='small'
                  onClick={resetForm}
                >
                  Reset
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  type='submit'
                  size='small'
                  disabled={loading || user.switch_user}
                >
                  Add to Cart
                </Button>

              </CardActions>
            </ValidatorForm>
          </Card>
        </Grid>
        {groups.length ? (
          <Grid item xs={12} md={7}>
            <Card variant='outlined'>
              <CardContent>
                {' '}
                <Typography variant='subtitle1' gutterBottom>
                  Available Roles
                </Typography>
                <EnhancedTable
                  key='model-user-table'
                  headCells={headCells}
                  rowsData={groups}
                  checkbox={false}
                  orderby='key_app_name'
                />
              </CardContent>
            </Card>
          </Grid>
        ) : null}
      </Grid>
    </div>

  )
}