/* It contains all the API functions which is releated to login page */

import axiosInstance from '../../middleware/Interceptor'

// to get cart size of current user
function cartSize (username) {
  return axiosInstance.get(`/api/v1/cart/getcartsize?username=${username}`)
}
// to get cart items of current user
function viewCart (username) {
  return axiosInstance.get(`/api/v1/cart/viewcart?username=${username}`)
}
// delete any one item from cart
function deleteFromCart (id, username) {
  return axiosInstance.get(`/api/v1/cart/deleteFromCart?cartId=${id}&username=
${username}`)
}
// delete all items from cart
function emptyCart (username) {
  return axiosInstance.get(`/api/v1/cart/emptyCart?username=${username}`)
}
// submit all requests from cart
function submitFinalRequest (username, input) {
  return axiosInstance.post(`/api/v1/cart/submitfinalRequest?username=
${username}`, input)
}

const CartService = {
  cartSize,
  viewCart,
  deleteFromCart,
  emptyCart,
  submitFinalRequest
}

export default CartService
