import React, {
  useContext, useRef
} from 'react'
import InfoIcon from '@material-ui/icons/Info'
import {
  Button, Card, CardActions, CardContent, Typography, Grid, createStyles,
  makeStyles, Tooltip, FormControl, LinearProgress, List, ListItem,
  FormControlLabel, Checkbox, Theme
} from '@material-ui/core'
import {
  ValidatorForm, TextValidator
} from 'react-material-ui-form-validator'
import {
  Autocomplete, Alert
} from '@material-ui/lab'
import UserContext from '../contexts/UserContext'
import requestAccess from '../../services/api/requestaccessService'
import reportServices from '../../services/api/reportServices'
import AlertDialog from '../alertbox/alertboxComponent'
import adminServices from '../../services/api/adminConfigService'

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    flexGrow: 1
  },
  formControl: {
    margin: theme.spacing(1),
    width: '98%'
  },
  footer: {
    margin: theme.spacing(2),
    float: 'right'
  },
  success: {
    backgroundColor: theme.palette.success.light
  },
  pending: {
    backgroundColor: '#fff9c4'
  },
  alertroot: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  }
}))

export default function AddUserComponent () {
  const [loading, setLoading] = React.useState(false)
  const [loadingbar, setLoadingBar] = React.useState(false)
  const [successAlert, setsuccessAlert] = React.useState(false)
  const [errorAlert, seterrorAlert] = React.useState(false)
  const [failalertMessage, setFailalertMessage] = React.useState(null)
  const [successalertMessage, setSuccessalertMessage] = React.useState(null)
  const [allProjects, setAllprojects] = React.useState([])
  const [allRoles, setAllroles] = React.useState([])
  const [allUsers, setAllUsers] = React.useState([])
  const [selectedGroups, setSelectedGroups] = React.useState([])
  const [alertbox, Setalertbox] = React.useState(false)
  const [oneGroupAlert, setOneGroupAlert] = React.useState(false)
  const [ouAlertbox, SetOuAlertbox] = React.useState(false)
  const [keyPrefix, setKeyPrefix] = React.useState(null)
  const [realKey, setRealKey] = React.useState(null)
  const [ouAlertMessage, setOuAlertMessage] = React.useState(null)
  const classes = useStyles()
  const form = useRef(null)
  const {
    user
  } = useContext(UserContext)
  const [disableCheckBoxForQTest,
    setDisableCheckBoxForQTest] = React.useState(false)

  // form data declaration
  const [formData, setFormData] = React.useState({
    projectkey: '',
    project: null,
    chooseUser: '',
    chooseUserName: '',
    usernameDisplay: ''
  })

  // reset form
  const resetForm = () => {
    setFormData({
      projectkey: '',
      project: null,
      chooseUser: '',
      chooseUserName: '',
      usernameDisplay: ''
    })
    setAllUsers([])
    setAllprojects([])
    setAllroles([])
    setSelectedGroups([])
    setKeyPrefix(null)
    setRealKey(null)
    seterrorAlert(false)
    setDisableCheckBoxForQTest(false)
  }

  //
  const getClassname = (warnType) => {
    switch (warnType) {
    case 'Grant':
      return classes.success
    case 'Pending':
      return classes.pending
    default:
      return null
    }
  }

  /* function to get the projects list based on the search key */
  const getProjects = (key) => {
    if (key) {
      setLoadingBar(true)
      reportServices.getAllToolsAndKeysWithName(key).then((response) => {
        setLoadingBar(false)
        setAllprojects(response.data)
      })
    }
  }

  /* get Roles based on project key selection */
  const changeKey = (value) => {
    if (value) {
      setAllroles([])
      setKeyPrefix(value.key_prefix)
      setRealKey(value.key_name)
      setLoading(true)
      reportServices.getGroupsWithOwnerAndApproverGroup(value.key_name,
        value.toolname).then((response1) => {
        requestAccess.GetRolesForProjectsValidation(formData.chooseUserName,
          value.toolname, value.key_name, 1).then((response2) => {
          setLoading(false)
          setFormData({
            ...formData,
            projectkey: value
          })
          const tempFirstArray = response1.data
          const tempSecondArray = response2.data
          tempFirstArray.map((item, i) => {
            tempSecondArray.map((item2, j) => {
              item.group_name = item.group_name.toLowerCase()
              if (item2.already_access &&
                item.group_name === item2.already_access.toLowerCase()) {
                item.warning = 'Selected user already granted this role'
                item.warnType = 'Grant'
              } else if (item2.cart_requests &&
                item.group_name === item2.cart_requests.toLowerCase()) {
                item.warning = `Selected role with user already 
                  added to the cart by ${item2.cart_user}`
                item.warnType = 'Pending'
              } else if (item2.pending_requests &&
                item.group_name === item2.pending_requests.toLowerCase()) {
                item.warning = `Selected role with user 
                  is Pending for Approval`
                item.warnType = 'Pending'
              } else if (item2.processing_request &&
                item.group_name === item2.processing_request.toLowerCase()) {
                item.warning = 'Selected role with user is Processing in IDMS'
                item.warnType = 'Pending'
              } else if (item2.training_requests &&
                item.group_name === item2.training_requests.toLowerCase()) {
                item.warning = `Selected user with role is waiting
                for training check.`
                item.warnType = 'Pending'
              }
              if (value.toolname.toLowerCase() === 'qtest' &&
                  ((item2.cart_requests &&
                    item.group_name === item2.cart_requests.toLowerCase()) ||
                    (item2.already_access &&
                    item.group_name === item2.already_access.toLowerCase()) ||
                    (item2.pending_requests &&
                    item.group_name === item2.pending_requests.toLowerCase()) ||
                    (item2.processing_request &&
                    item.group_name === item2.processing_request.toLowerCase())
                  )) {
                setDisableCheckBoxForQTest(true)
              }
            })
          })
          setAllroles((prevrows1) => ([...prevrows1, ...tempFirstArray]))
        })
      })
    }
  }

  /* function to get the users list based on the search key */
  const getUsers = (username) => {
    // setFormData({ ...formData, searchkey: username })
    setLoadingBar(true)
    requestAccess.getallusersforautocomplete(username).then((response) => {
      setLoadingBar(false)
      setAllUsers(response.data)
    })
  }

  /* get Roles based on project key selection */
  const changeUser = (value) => {
    if (value) {
      setFormData({
        ...formData,
        chooseUser: value,
        chooseUserName: value.name,
        projectkey: '',
        project: null
      })
      setSelectedGroups([])
      setAllroles([])
    }
  }

  /* Click checkbox function */
  const clickCheckbox = (role, value, index) => {
    const tempArray = allRoles
    if (tempArray[index].warnType) {
      tempArray[index].warnType = null
      setAllroles([...tempArray])
    } else if (selectedGroups.includes(role.group_name)) {
      const checkedGroup = selectedGroups
      checkedGroup.splice(selectedGroups.indexOf(role.group_name), 1)
      setSelectedGroups([...checkedGroup])
    } else {
      requestAccess
        .canAccountRequestAccessToGroup(role.toolname,
          keyPrefix,
          role.group_name,
          formData.chooseUserName,
          formData.projectkey.key_name)
        .then((response) => {
          if (response.data[0].check) {
            selectedGroups.push(role.group_name)
          } else {
            const ou = response.data[0]
            const message =
                ou !== null ? (
                  <div>
                    <Typography variant='subtitle2' gutterBottom>
                      Only users that belong to any one of the following
                      organization unit can request for this group
                    </Typography>
                    <ul>
                      {ou.roles.map((roleName) => (
                        <li>{roleName.ou}</li>
                      ))}
                    </ul>

                    <Typography variant='subtitle2' gutterBottom>
                      This account is a member of following Organization Unit:
                    </Typography>
                    <ul>
                      <li>{ou.user_roles}</li>
                    </ul>
                  </div>
                ) : null
            SetOuAlertbox(true)
            setOuAlertMessage(message)
          }
          setSelectedGroups([...selectedGroups])
          if (role.toolname.toLowerCase() === 'qtest' &&
              selectedGroups.length === 1) {
            setDisableCheckBoxForQTest(true)
          }
        })
    }
  }

  // submit function
  const handleSubmit = () => {
    if (selectedGroups.length) {
      Setalertbox(true)
    } else {
      setOneGroupAlert(true)
    }
  }


  /** Close alert */
  const handleClose = () => {
    Setalertbox(false)
  }
  /** Close One user alert */
  const handleOneUserClose = () => {
    setOneGroupAlert(false)
  }

  /** Close OU alert */
  const handleOuClose = () => {
    SetOuAlertbox(false)
  }

  /** ? Submit function */
  const confirmSubmit = () => {
    setLoading(true)
    const input = {
      user: formData.chooseUserName,
      grouparray: selectedGroups,
      projectkey: realKey,
      key_prefix: keyPrefix,
      toolname: formData.projectkey.toolname
    }
    adminServices.userToGroup('add', input).then((response) => {
      setLoading(false)
      if (response.data === 'success') {
        setSuccessalertMessage('User added successfully!')
        setsuccessAlert(true)
        resetForm()
        setTimeout(() => {
          setsuccessAlert(false)
        }, 20000)
      } else {
        setFailalertMessage(response.data)
        seterrorAlert(true)
      }
    }, (error) => {
      if (error) {}
      setLoading(false)
      setFailalertMessage(`Something went wrong. 
        Contact system administrator!`)
      seterrorAlert(true)
    })
    Setalertbox(false)
  }

  return (
    <div>
      {loading ? <LinearProgress /> : null}
      {' '}
      <div className={classes.alertroot}>
        {' '}
        {successAlert ? (
          <Alert severity='success'>{successalertMessage}</Alert>
        ) : null}
        {errorAlert
          ? (
            <Alert severity='error' onClose={() => seterrorAlert(false)}>
              {failalertMessage}
            </Alert>
          ) : null}
      </div>
      <Grid container spacing={1} className={classes.root}>
        <Grid item xs={12} md={7}>
          <Card variant='outlined'>
            <ValidatorForm
              ref={form}
              onSubmit={(event) => handleSubmit(event)}
            >
              <CardContent>
                <Typography variant='subtitle1' gutterBottom>
                  Add Users to Group
                </Typography>
                <div>
                  <FormControl className={classes.formControl}>
                    <Autocomplete
                      id='choose-user-auto-complete'
                      value={formData.chooseUser}
                      className={classes.inputDisplay}
                      options={allUsers}
                      getOptionLabel={
                        (option) => (
                          option.name
                            ? `${option.name} - ${option.displayname}`
                            : option)
                      }
                      loading={allUsers.length === 0 && loadingbar}
                      getOptionSelected={
                        (option, userValue) => option.name === userValue.name
                      }
                      filterOptions={(options, state) => options}
                      onChange={(event, userValue) => changeUser(userValue)}
                      renderInput={(params) => (
                        <TextValidator
                          {...params}
                          name='chooseUser'
                          onChange={(event) => getUsers(event.target.value)}
                          value={formData.chooseUser}
                          label='Choose a user '
                          validators={['required']}
                          errorMessages={['This field is required']}
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <Autocomplete
                      id='select-project'
                      options={allProjects}
                      value={formData.projectkey ? formData.projectkey : ''}
                      getOptionLabel={
                        (option) => (option.key ? option.key : option)
                      }
                      loading={allProjects.length === 0 && loadingbar}
                      className={classes.inputDisplay}
                      disabled={formData.chooseUser === ''}
                      getOptionSelected={
                        (option, value) => option.key === value.key
                      }
                      onChange={(event, value) => changeKey(value)}
                      renderInput={(params) => (
                        <TextValidator
                          {...params}
                          label='Type a valid project key *'
                          name='projectkey'
                          onChange={(event) => getProjects(event.target.value)}
                          value={formData.projectkey}
                          validators={['required']}
                          errorMessages={['This field is required']}
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </CardContent>
              <CardActions className={classes.footer}>
                <Button
                  variant='contained'
                  size='small'
                  onClick={resetForm}
                >
                  Reset
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  type='submit'
                  size='small'
                  disabled={loading || user.switch_user}
                >
                  Add User
                </Button>
              </CardActions>
            </ValidatorForm>
          </Card>
        </Grid>
        {allRoles.length
          ? (
            <Grid item xs={12} md={5}>
              <Card variant='outlined'>
                <CardContent>
                  <Typography variant='subtitle1' gutterBottom>
                    Available Roles
                  </Typography>
                  <List component='nav' aria-label='main mailbox folders'>
                    {allRoles.map((role, i) => (
                      <ListItem
                        key={role.group_name}
                        className={getClassname(role.warnType)}
                      >
                        <FormControlLabel
                          control={(
                            <Checkbox
                              name='checkedA'
                              value={role}
                              checked={role.warnType ||
                                selectedGroups.includes(role.group_name)}
                              label={role.group_name}
                              onChange={
                                (event, value) => (
                                  clickCheckbox(role, value, i))
                              }
                              disabled={disableCheckBoxForQTest}
                            />
                          )}
                        />
                        {role.group_name}
                        {role.warnType ? (
                          <Tooltip
                            title={(
                              <span style={{
                                fontSize: 12,
                                fontFamily: 'Verdana'
                              }}
                              >
                                {role.warning}
                              </span>
                            )}
                          >
                            <InfoIcon color='action' fontSize='small' />
                          </Tooltip>
                        ) : null}
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          ) : null}
      </Grid>
      <AlertDialog
        handleClose={handleOuClose}
        alertopen={ouAlertbox}
        message={ouAlertMessage}
        key='alert1'
        title='Alert'
        okbuttonalert
      />
      <AlertDialog
        handleClose={handleOneUserClose}
        alertopen={oneGroupAlert}
        message='Select a minimum of one group and then try to add'
        key='alert3'
        title='Alert'
        okbuttonalert
      />
      <AlertDialog
        handleClose={handleClose}
        alertopen={alertbox}
        confirmbutton={confirmSubmit}
        key='alert2'
        message='Are you sure want to add the user to selected groups?'
        okbuttonalert={false}
        title='Alert'
      />

    </div>
  )
}