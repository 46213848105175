import React, {
  useState, useEffect
} from 'react'
import {
  makeStyles
} from '@material-ui/core/styles'
/* import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow' */
import {
  LinearProgress
} from '@material-ui/core'
import requestAccessService from '../../services/api/requestaccessService'
import EnhancedTable from '../sections/enhancedTable'

const columns = [
  {
    id: 'id',
    label: 'ID',
    minWidth: 100
  },
  {
    id: 'training_title',
    label: 'Training Title',
    minWidth: 170
  },
  /* {
    id: 'username',
    label: 'Username',
    minWidth: 170
  },
  {
    id: 'displayname',
    label: 'Display Name',
    minWidth: 170
  }, */
  {
    id: 'toolname',
    label: 'Tool',
    minWidth: 170
  },
  {
    id: 'project_key',
    label: 'Project Key',
    minWidth: 170
  },
  {
    id: 'role_name',
    label: 'Role Name',
    minWidth: 170
  },
  {
    id: 'group_name',
    label: 'Group Name',
    minWidth: 170
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 170
  },
  {
    id: 'requested_date',
    label: 'Requested Date',
    minWidth: 170
  },
  {
    id: 'requested_by',
    label: 'Requested By',
    minWidth: 170
  },
  {
    id: 'requested_for',
    label: 'Requested for',
    minWidth: 170
  }
]


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  container: {
    maxHeight: 440
  },
  nodatatext: {
    ...theme.typography.subtitle1,
    padding: theme.spacing(8),
    textAlign: 'center'
  }
}))

export default function TrainingRequest () {
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [rows, setRows] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [tableData, setTableData] = useState({
    columns: [],
    rows: []
  })

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  React.useEffect(() => {
    initMethod()
  }, [])

  const initMethod = () => {
    setLoading(true)
    requestAccessService.loadTrainingTab().then((response) => {
      setRows([...response.data])
      setLoading(false)
    })
  }

  //  API CALL
  /* useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('our-api')
        const data = await response.json()
        setTableData(data)
      } catch (error) {
        console.error(error)
      }
    }
    fetchData()
  }, []) */


  return (
    <>
      <div>
        {loading ? <LinearProgress /> : null}
        {
          !loading && rows.length ? (
            <EnhancedTable
              key='john-table'
              orderby='action_date_sort'
              sortorder='desc'
              headCells={columns}
              rowsData={rows}
            />
          ) : null
        }
        {!loading && !rows.length ? (
          <div className={classes.nodatatext}>No Record under training.</div>
        ) : null}
      </div>
    </>
    // <Paper className={classes.root}>
    //   <TableContainer className={classes.container}>
    //     <Table stickyHeader aria-label='sticky table'>
    //       <TableHead>
    //         <TableRow>
    //           {columns.map((column) => (
    //             <TableCell
    //               key={column.id}
    //               align={column.align}
    //               style={{
    //                 minWidth: column.minWidth
    //               }}
    //             >
    //               {column.label}
    //             </TableCell>
    //           ))}
    //         </TableRow>
    //       </TableHead>
    //       <TableBody>
    //         {rows
    //           .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    //           .map((row) => (
    //             <TableRow hover role='checkbox' tabIndex={-1} key={row.id}>
    //               {columns.map((column) => {
    //                 const value = row[column.id]
    //                 return (
    //                   <TableCell key={column.id} align={column.align}>
    //                     {column.format && typeof value === 'number'
    //                       ? column.format(value)
    //                       : value}
    //                   </TableCell>
    //                 )
    //               })}
    //             </TableRow>
    //           ))}
    //       </TableBody>
    //     </Table>
    //   </TableContainer>
    //   <TablePagination
    //     rowsPerPageOptions={[10, 25, 100]}
    //     component='div'
    //     count={rows.length}
    //     rowsPerPage={rowsPerPage}
    //     page={page}
    //     onPageChange={handleChangePage}
    //     onRowsPerPageChange={handleChangeRowsPerPage}
    //   />
    // </Paper>
  )
}
