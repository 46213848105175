import React, {
  useContext
} from 'react'
import {
  Button,
  Card,
  CardContent,
  Typography,
  makeStyles,
  FormControl,
  LinearProgress,
  InputLabel,
  Select,
  MenuItem,
  TextField
} from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'
import PictureInPictureIcon from '@material-ui/icons/PictureInPicture'
import ListAltIcon from '@material-ui/icons/ListAlt'
import InsertChartIcon from '@material-ui/icons/InsertChart'
import TrackChangesIcon from '@material-ui/icons/TrackChanges'
import OndemandVideoTwoToneIcon from '@material-ui/icons/OndemandVideoTwoTone'
import {
  Alert
} from '@material-ui/lab'
import cloneDeep from 'lodash/cloneDeep'
import GroupIcon from '@material-ui/icons/Group'
import CategoryIcon from '@material-ui/icons/Category'
import UserContext from '../contexts/UserContext'
import adminConfigService from '../../services/api/adminConfigService'
import database from '../../assets/images/database.png'
import crowd from '../../assets/images/crowd.png'
import ad from '../../assets/images/ad.png'
import jiralogo from '../../assets/images/jira-logo.png'
import confluencelogo from '../../assets/images/confluence-logo.png'
import bitbucketlogo from '../../assets/images/bitbucket-logo.png'
import idms from '../../assets/images/idms.png'
import servicetask from '../../assets/images/servicetask.png'
import support from '../../assets/images/support.png'
import sonar from '../../assets/images/sonar.png'
import artifactlogo from '../../assets/images/Artifactory-Logo.png'
import jenkinslogo from '../../assets/images/jenkins_admin_logo.png'
import systemadmin from '../../assets/images/system_admin.png'
import provisioning from '../../assets/images/provisioning.png'
import knowledgebase from '../../assets/images/knowledgebase.jpg'
import ApplicationConfigComponent from './applicationConfigComponent'
import DatabaseConfigComponent from './databaseConfigComponent'
import ToolConfigComponent from './toolConfigComponent'
import IrisConfigComponent from './irisConfigComponent'
import ToolProvisioningComponent from './toolProvisioningComponent'
import AdminGroupAndUsersComponent from './adminGroupandUsersComponent'
import BitbucketAdminGroupComponent from './BitbucketAdminGroupComponent'
import ArtifactoryAdminComponent from './artifactoryAdminComponent'
import JenkinsAdminComponent from './jenkinsAdminCompnent'
import ApigeeAdminComponent from './apigeeAdminComponent'
import KubeconfigAdminComponent from './kubeconfigAdminCompnent'
import kubeconfiglogo from '../../assets/images/Cloudx_Kubernetes.png'
import GithubCopilotAdminComponent from './githubCopilotAdminComponent'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  formControl: {
    margin: theme.spacing(1),
    width: '20%'
  },
  footer: {
    margin: theme.spacing(2),
    float: 'right'
  },
  alertroot: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  },
  adminfieldset: {
    border: 0,
    borderTop: '1px solid #e9e9e9',
    margin: '20px 0 0 30px',
    paddingBottom: 10
  },
  listtext: {
    width: 'auto',
    'line-height': 1.42857,
    color: '#333',
    'font-weight': 600,
    'text-transform': 'uppercase'
  },
  square: {
    width: 180,
    height: 100,
    float: 'left',
    cursor: 'pointer'
  },
  circle: {
    margin: '20px 0 0 40px'
  },
  image: {
    cursor: 'pointer',
    width: 60,
    height: 60
  },
  icon: {
    fontSize: 60
  },
  usertext: {
    margin: '10px 0 0 49px'
  },
  longtext: {
    margin: '10px 0 0 29px'
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.8
  }
}))

export default function AdminConfigComponent () {
  const {
    user, setUser
  } = useContext(UserContext)
  const [loading, setLoading] = React.useState(false)
  const [successAlert, setsuccessAlert] = React.useState(false)
  const [errorAlert, seterrorAlert] = React.useState(false)
  const [environments, setEnvironments] = React.useState([])
  const [environment, setEnvironment] = React.useState('')
  const [environmentcopy, setEnvironemntCopy] = React.useState('')
  const [configdata, setConfigData] = React.useState(null)
  const [configdatacopy, setConfigDataCopy] = React.useState(null)
  const [bannerdatacopy, setBannerDataCopy] = React.useState(null)
  const [currenttab, setCurrentTab] = React.useState('main')
  const [currentsection, setCurrentSection] = React.useState('')
  const [bannerdata, setBannerData] = React.useState(null)
  const [maincontent, setMainContent] = React.useState([])
  const classes = useStyles()

  const iconArray = [
    {
      key: 'Settings',
      icon: <SettingsIcon color='primary' className={classes.icon} />,
      isreact: true
    },
    {
      key: 'Banner',
      icon: <PictureInPictureIcon color='primary' className={classes.icon} />,
      isreact: true
    },
    {
      key: 'Logger',
      icon: <ListAltIcon color='primary' className={classes.icon} />,
      isreact: true
    },
    {
      key: 'Knowledge Base',
      icon: knowledgebase,
      isreact: false
    },
    {
      key: 'Database',
      icon: database,
      isreact: false
    },
    {
      key: 'Crowd',
      icon: crowd,
      isreact: false
    },
    {
      key: 'Active Directory',
      icon: ad,
      isreact: false
    },
    {
      key: 'Jira',
      icon: jiralogo,
      isreact: false
    },
    {
      key: 'Confluence',
      icon: confluencelogo,
      isreact: false
    },
    {
      key: 'Bitbucket',
      icon: bitbucketlogo,
      isreact: false
    },
    {
      key: 'Sonar',
      icon: sonar,
      isreact: false
    },
    {
      key: 'IDMS Group Creation',
      icon: idms,
      isreact: false
    },
    {
      key: 'Service Task',
      icon: servicetask,
      isreact: false
    },
    {
      key: 'Incident',
      icon: support,
      isreact: false
    },
    {
      key: 'Risk Assessment',
      icon: <InsertChartIcon color='primary' className={classes.icon} />,
      isreact: true
    },
    {
      key: 'Artifactory',
      icon: artifactlogo,
      isreact: false
    },
    {
      key: 'Admin Group',
      icon: systemadmin,
      isreact: false
    },
    {
      key: 'Tool Provisioning',
      icon: provisioning,
      isreact: false
    },
    {
      key: 'Account Owner Exception',
      icon: <GroupIcon color='action' className={classes.icon} />,
      isreact: true
    },
    {
      key: 'Bitbucket Admin Group',
      icon: <CategoryIcon color='action' className={classes.icon} />,
      isreact: true
    },
    {
      key: 'Jenkins',
      icon: jenkinslogo,
      isreact: false
    },
    {
      key: 'Apigee',
      icon: <ListAltIcon color='primary' className={classes.icon} />,
      isreact: true
    },
    {
      key: 'Cloudx_Kubernetes',
      icon: kubeconfiglogo,
      isreact: false
    },
    {
      key: 'Github Copilot',
      icon: <TrackChangesIcon color='primary' className={classes.icon} />,
      isreact: true
    },
    {
      key: 'Summit Integration',
      icon:
        <OndemandVideoTwoToneIcon color='primary' className={classes.icon} />,
      isreact: true
    }
  ]

  /* get config data */
  function getConfig (value) {
    setLoading(true)
    adminConfigService.adminconfig(value).then((configresponse) => {
      setLoading(false)
      if (value === '') {
        setEnvironment(configresponse.data.environment)
        setEnvironemntCopy(configresponse.data.environment)
      }
      const data = configresponse.data.configparams
      data.jira.xrayTestExecutionApiFieldCheck =
        JSON.stringify(data.jira.xrayTestExecutionApiFieldCheck, undefined, 4)
      data.iris.token_validation.request_body =
        JSON.stringify(data.iris.token_validation.request_body, undefined, 4)
      data.iris.configuration_item =
        JSON.stringify(data.iris.configuration_item)
      data.iris.packages = JSON.stringify(data.iris.packages)
      data.idmsGroups.token_validation.request_body =
        JSON.stringify(data.idmsGroups.token_validation.request_body,
          undefined, 4)
      data.apigeeDetails.auth_token =
        JSON.stringify(data.apigeeDetails.auth_token, undefined, 4)
      data.apigeeDetails.create_user =
        JSON.stringify(data.apigeeDetails.create_user, undefined, 4)
      setConfigData(data)
      setConfigDataCopy(cloneDeep(data))
    })
  }
  // It will be called when the page gets loaded, similer to componentDidMount
  React.useEffect(() => {
    setLoading(true)
    adminConfigService.getAdminConfigWidgets().then((responseAdWidgt) => {
      const maindataFunction = responseAdWidgt.data
      const framedConfigArr = []
      maindataFunction.map((item, i) => {
        if (maindataFunction[i]) {
          const admInnerValues = maindataFunction[i].values
          const innerFramedConfigArr = []
          admInnerValues.map((item1, b) => {
            const backendComp = item1.icon
            const admIcon =
            iconArray.filter((icn) => icn.key ===
          backendComp)
            const innerJs = {
              heading: item1.heading,
              icon: admIcon[0]?.icon,
              isreact: admIcon[0]?.isreact
            }
            innerFramedConfigArr.push(innerJs)
          })
          const js = {
            heading: item.heading,
            values: innerFramedConfigArr
          }
          framedConfigArr.push(js)
        }
      })
      setMainContent(framedConfigArr)
      adminConfigService.getAllEnvironment('').then((response) => {
        setEnvironments(response.data)
        setLoading(false)
        getConfig('')
      })
    })
  }, [])
  /* to set the current section and tab */
  function changeTab (tabname, section) {
    setCurrentSection(section)
    setCurrentTab(tabname)
    if (tabname === 'Banner' && !bannerdata) {
      getBannerdata()
    }
  }
  /* to change the values when edit */
  const handleChange = (type, e, child, richTextEditor) => {
    const configdata1 = configdata
    const subtype = configdata1[type]
    if (richTextEditor) {
      subtype[e.target.name] = e
    } else if (child) {
      subtype[child][e.target.name] = e.target.value
    } else {
      subtype[e.target.name] = e.target.value
    }

    if (e.target.name === 'groupsAllowedToCreate') {
      subtype[e.target.name] = e.target.value.split(',')
    }
    configdata1[type] = subtype
    setConfigData({
      ...configdata1
    })
  }

  const changeUser = (value) => {
    const configdata1 = configdata
    configdata1.admin.account_owner_exception = value
    setConfigData({
      ...configdata1
    })
  }
  /* to change the array json values when edit */
  const groupChange = (index, e, type, child1, child2) => {
    const configdata1 = configdata
    const subtype = configdata1[type]
    if (child2) {
      subtype[child1][child2][index][e.target.name] = e.target.value
    } else {
      subtype[child1][index][e.target.name] = e.target.value
    }
    configdata1[type] = subtype
    setConfigData({
      ...configdata1
    })
  }
  /* function to get config data for selected environment */
  const changeEnv = (e) => {
    setEnvironment(e.target.value)
    getConfig(e.target.value)
  }
  /* change function for toggle */
  const switchToggle = (type, e) => {
    const configdata1 = configdata
    const subtype = configdata1[type]
    subtype[e.target.name] = e.target.checked
    configdata1[type] = subtype
    setConfigData({
      ...configdata1
    })
  }
  /* change function for banner data edit */
  const setBanner = (e) => {
    if (e.target.name === 'visibility') {
      setBannerData({
        ...bannerdata,
        [e.target.name]: e.target.checked
      })
    } else {
      setBannerData({
        ...bannerdata,
        [e.target.name]: e.target.value
      })
    }
  }
  /* to get the banner data */
  const getBannerdata = () => {
    adminConfigService.getBannerData().then((response) => {
      setBannerData(response.data[0])
      setBannerDataCopy(cloneDeep(response.data[0]))
    })
  }
  /* cancel function to cancel changed data */
  const Cancel = () => {
    if (currenttab === 'Banner') {
      setBannerData(cloneDeep(bannerdatacopy))
    } else {
      setConfigData(cloneDeep(configdatacopy))
    }
    setCurrentTab('main')
  }
  /** Get the required admin component/ */
  const getAdminComponents = (tabname) => {
    switch (tabname) {
    case 'Admin Group':
      return (
        <AdminGroupAndUsersComponent
          page={tabname}
          configdata={configdata}
          handleChange={handleChange}
          changeUser={changeUser}
        />
      )
    case 'Account Owner Exception':
      return (
        <AdminGroupAndUsersComponent
          page={tabname}
          configdata={configdata}
          handleChange={handleChange}
          changeUser={changeUser}
        />
      )
    case 'Bitbucket Admin Group':
      return (
        <BitbucketAdminGroupComponent
          configdata={configdata}
          handleChange={handleChange}
        />
      )
    case 'Tool Provisioning':
      return (
        <ToolProvisioningComponent
          setLoading={
            (value) => setLoading(value)
          }
          Cancel={() => Cancel()}
        />
      )
    case 'Artifactory':
      return (
        <ArtifactoryAdminComponent
          configdata={configdata}
          handleChange={handleChange}
        />
      )
    case 'Jenkins':
      return (
        <JenkinsAdminComponent
          configdata={configdata}
          handleChange={handleChange}
        />
      )
    case 'Apigee Orgs':
      return (
        <ApigeeAdminComponent
          configdata={configdata}
          handleChange={handleChange}
        />
      )
    case 'Kube Configuration':
      return (
        <KubeconfigAdminComponent
          configdata={configdata}
          handleChange={handleChange}
        />
      )
    case 'Github Copilot':
      return (
        <GithubCopilotAdminComponent
          configdata={configdata}
          handleChange={handleChange}
        />
      )
    default: return null
    }
  }
  /* function to return pages based on the currentsection */
  const getPageDetails = () => {
    switch (currentsection) {
    case 'Application Configuration':
      return (
        <ApplicationConfigComponent
          page={currenttab}
          configdata={currenttab === 'Banner' ? bannerdata : configdata}
          handleChange={currenttab === 'Banner' ? setBanner : handleChange}
          switchToggle={switchToggle}
        />
      )
    case 'Database Configuration':
      return (
        <DatabaseConfigComponent
          page={currenttab}
          configdata={configdata}
          handleChange={handleChange}
        />
      )
    case 'Tool Configuration':
      return (
        <ToolConfigComponent
          page={currenttab}
          configdata={configdata}
          handleChange={handleChange}
          groupChange={groupChange}
        />
      )
    case 'IRIS Configuration':
      return (
        <IrisConfigComponent
          page={currenttab}
          configdata={configdata}
          handleChange={handleChange}
          groupChange={groupChange}
        />
      )
    case 'Admin Configuration':
      return getAdminComponents(currenttab)

    default: return null
    }
  }
  /* to update the changed values of banner data */
  function updateBannerData () {
    setLoading(true)
    adminConfigService.updateBannerData(bannerdata).then((response) => {
      setLoading(false)
      setsuccessAlert(true)
      setUser({
        ...user,
        bannerdata
      })
      setBannerDataCopy(cloneDeep(bannerdata))
      setTimeout(() => {
        setsuccessAlert(false)
      }, 20000)
    },
    (error) => {
      if (error) {
        setLoading(false)
        seterrorAlert(true)
      }
    })
  }

  /* save function */
  const saveConfig = () => {
    if (currenttab === 'Banner') {
      updateBannerData()
    } else {
      setLoading(true)
      adminConfigService.saveAdminConfig(environment,
        configdata).then((response) => {
        setLoading(false)
        setsuccessAlert(true)
        setConfigDataCopy(cloneDeep(configdata))
        if (response.data) {
          setUser({
            ...user,
            email: configdata.settings.email_notification,
            helpurl: configdata.knowledgebase.help_page,
            clone_repo_page: configdata.knowledgebase.clone_repo_page,
            project_creation: configdata.knowledgebase.project_creation,
            request_access_page: configdata.knowledgebase.request_access_page,
            riskurl: configdata.knowledgebase.risk_assessment_page
          })
        }
        setTimeout(() => {
          setsuccessAlert(false)
        }, 20000)
      },
      (error) => {
        if (error) {
          setLoading(false)
          seterrorAlert(true)
        }
      })
    }
  }
  return (
    <div>
      {loading ? <LinearProgress /> : null}
      {' '}
      <div className={classes.alertroot}>
        {' '}
        {successAlert ? (
          <Alert severity='success'>
            App Dev Portal Configuration Saved
          </Alert>
        ) : null}
        {errorAlert ? (
          <Alert
            severity='error'
            onClose={() => seterrorAlert(false)}
          >
            Something went wrong. Contact system
            administrator!
          </Alert>
        ) : null}
      </div>
      {currenttab === 'main' ? (
        <div>
          {maincontent.map((item, i) => (
            <fieldset className={classes.adminfieldset} key={item.heading}>
              <legend className={classes.listtext}>
                {item.heading}
                &nbsp;&nbsp;&nbsp;&nbsp;
              </legend>
              {item.values.map((val) => (
                <div
                  className={classes.square}
                  onClick={
                    () => changeTab(val.heading, item.heading)
                  }
                  key={val.heading}
                >
                  <div className={classes.circle}>
                    {val.isreact ? val.icon : (
                      <img
                        src={val.icon}
                        alt=''
                        className={classes.image}
                      />
                    )}
                  </div>
                  <p className={val.heading.length > 11
                    ? classes.longtext : classes.usertext}
                  >
                    {val.heading}
                  </p>
                </div>
              ))}

            </fieldset>
          ))}

        </div>
      )
        : (
          <Card>
            <CardContent>

              <Typography variant='subtitle1' gutterBottom>
                {currenttab}
                {currenttab !== 'Tool Provisioning' && (
                  <Button
                    variant='contained'
                    size='small'
                    onClick={() => Cancel()}
                    className={classes.footer}
                  >
                    Cancel
                  </Button>
                )}
                {environment === environmentcopy &&
                currenttab !== 'Tool Provisioning' &&
                  (
                    <Button
                      variant='contained'
                      color='primary'
                      type='submit'
                      size='small'
                      disabled={loading || user.switch_user}
                      onClick={() => saveConfig()}
                      className={classes.footer}
                    >
                      Save
                    </Button>
                  )}

              </Typography>
              {currenttab !== 'Tool Provisioning' && (
                <FormControl className={classes.formControl}>
                  <InputLabel id='demo-simple-environment-label'>
                    Environment
                  </InputLabel>
                  <Select
                    labelId='demo-simple-environment-label'
                    value={environment}
                    onChange={changeEnv}
                  >
                    {environments.map((value) => (
                      <MenuItem
                        value={value.environment}
                        key={value.environment}
                      >
                        {value.environment}
                      </MenuItem>
                    ))}
                  </Select>

                </FormControl>
              )}
              <div className={environment !== environmentcopy
                ? classes.disabled : null}
              >
                {configdata ? getPageDetails() : 'Loading...'}
              </div>
            </CardContent>

          </Card>
        )}

    </div>

  )
}
