import React from 'react'
import InfoIcon from '@material-ui/icons/Info'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Grid,
  MenuItem,
  withStyles,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  Checkbox,
  LinearProgress,
  Tooltip,
  Radio,
  Link,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent
  , DialogContentText, Table, TableBody, TableCell, TableHead, TableRow
} from '@material-ui/core'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import Modal from '@material-ui/core/Modal'
import PropTypes from 'prop-types'
import {
  Autocomplete, Alert
} from '@material-ui/lab'
import queryString from 'query-string'
import parse from 'html-react-parser'
import RadioButtons from '../inputs/radio'
import ToolsService from '../../services/api/toolsService'
import AlertDialog from '../alertbox/alertboxComponent'
import requestAccess from '../../services/api/requestaccessService'
import UserContext from '../contexts/UserContext'
import CartService from '../../services/api/cartService'
import EmailServices from '../../services/api/emailServices'
import LoginService from '../../services/api/loginService'
import ApigeeService from '../../services/api/apigeeService'
import SurveyService from '../../services/api/surveyService'
import globalData from '../../data/globalData.json'

const styles = (theme) => ({
  assignedButton: {
    backgroundColor: 'green',
    color: 'white',
    pointerEvents: 'none' // This makes the button unclickable
  },
  line: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: theme.spacing(1, 0),
    marginLeft: '3%',
    width: '80%'
  },
  keyStyle: {
    width: '60%',
    textAlign: 'left'
  },
  buttonStyle: {
    width: '40%',
    textAlign: 'left'
  },
  imageStyle: {
    width: '7%',
    padding: '1%',
    marginRight: '10px'
  },
  assignImageStyle: {
    marginLeft: '34%',
    width: '30%',
    padding: '1%',
    marginRight: '10px'
  },
  closeStyle: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    fontSize: 30,
    color: 'grey'
  },
  itemStyle: {
    display: 'flex',
    alignItems: 'center'
  },
  divStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px'
  },
  modalStyle: {
    position: 'absolute',
    top: '15%',
    left: '36.5%',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '5px',
    width: '30%'
  },
  innerModalStyle: {
    position: 'absolute',
    top: '15%',
    left: '19%',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '18px',
    width: '60%'
  },
  h2StyleRed: {
    borderBottom: '10px solid red',
    paddingBottom: '5px'
  },
  h2StyleGreen: {
    borderBottom: '2px solid green',
    paddingBottom: '5px'
  },
  root: {
    flexGrow: 1
  },
  formControl: {
    margin: theme.spacing(1),
    width: '98%'
  },
  footer: {
    margin: theme.spacing(2),
    float: 'right'
  },
  success: {
    backgroundColor: theme.palette.success.light
  },
  pending: {
    backgroundColor: '#fff9c4'
  },
  alertroot: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  }
})
const parseHtml = (htmlMessage) => parse(htmlMessage)

class ByUserComponent extends React.Component {
  static contextType = UserContext

  constructor (props) {
    super(props)
    this.state = {
      formData: {
        email: '',
        password: '',
        toolname: '',
        projectkey: '',
        project: null,
        comments: '',
        username: '',
        usernameDisplay: '',
        clustor: '',
        org: '',
        apigeeTeam: ''
      },
      alert: false,
      alertbox: false,
      alertboxMessage: '',
      ou: null,
      buttontype: null,
      successalertMessage: '',
      failalertMessage: '',
      successAlert: false,
      errorAlert: false,
      assignedModal: false,
      summitModal: false,
      currentPage: 1,
      summitResponses: [],
      toAssign: {
      },
      assigned: {
      },
      assignLoad: false,
      allProjects: [],
      allUsers: [],
      allRoles: [],
      alltools: [],
      selectedGroups: [],
      radio: ['Self', 'Other'],
      selectedvalue: 'Self',
      loadingoptions: false,
      loadingoptions_user: false,
      loadingbar: false,
      clustorArray: [],
      disableCheckBoxForQTest: false,
      apigeeOrgs: [],
      apigeeOrgTeamsAndRoles: [],
      githubAlert: false,
      surveyValueRadio: '',
      surveyValueText: 'XENA Teams Channel, XENA confluence page ...',
      surveyData: [],
      surveyDialog: false,
      surveyJpmAlert: false,
      surveyJpmAlertMessage: '',
      open: false,
      trainingAlertMessage: '',
      pendingTraingList: []
    }
  }

  handleCloseTable = () => {
    this.setState({
      open: false
    })
  };

  /* function will be called when the user name selected from the options */
  changeUser = (value) => {
    if (value) {
      /*  to clear the values of other fields when username is changed */
      this.setState({
        allRoles: []
      })
      this.setState({
        allProjects: []
      })
      this.setState({
        selectedGroups: []
      })
      const {
        formData
      } = this.state
      formData.projectkey = ''
      formData.project = ''
      formData.toolname = ''
      formData.clustor = ''
      formData.org = ''
      formData.apigeeTeam = ''
      formData.username = value.name
      formData.usernameDisplay = `${value.name} - ${value.displayname}`
      this.setState({
        formData
      })
    }
  }

  checkSwitchUser = () => {
    const {
      user
    } = this.context
    return !!user.switch_user
  }

  /* function to change the clustor */
  changeClustor = (value) => {
    let username
    if (this.state.selectedvalue === 'Other') {
      username = this.state.formData.username
    } else {
      const {
        user
      } = this.context
      username = user.name
    }

    const {
      formData
    } = this.state

    formData.clustor = value.target.value

    this.setState({
      loadingbar: true
    })
    requestAccess
      .getRolesBasedOnClustor(this.state.formData.toolname.name,
        this.state.formData.project.key,
        this.state.formData.toolname.is_key,
        this.state.formData.clustor.clustor)
      .then((response) => {
        const allRoles = response.data
        requestAccess
          .GetRolesForProjectsValidation(username,
            this.state.formData.toolname.name,
            this.state.formData.project.key,
            1)
          .then((response1) => {
            this.setState({
              loadingbar: false
            })
            const validationdata = response1.data
            allRoles.forEach((role, index) => {
              if (validationdata.length) {
                validationdata.forEach((validation) => {
                  if (validation.already_access &&
                    role.name.toLowerCase() ===
                      validation.already_access.toLowerCase()) {
                    allRoles[index].status = 'access'
                    allRoles[index].message = `Selected user already 
                      granted this role`
                  } else if (validation.cart_requests &&
                    role.name.toLowerCase() ===
                      validation.cart_requests.toLowerCase()) {
                    allRoles[index].status = 'cart'
                    allRoles[index].message = `Selected role with user already 
                      added to the cart by ${validation.cart_user}`
                  } else if (validation.pending_requests &&
                    role.name.toLowerCase() ===
                      validation.pending_requests.toLowerCase()) {
                    allRoles[index].status = 'pending'
                    allRoles[index].message = `Selected role with user 
                      is pending for approval`
                  } else if (validation.processing_request &&
                    role.name.toLowerCase() ===
                      validation.processing_request.toLowerCase()) {
                    allRoles[index].status = 'pending'
                    allRoles[index].message = `Selected role with user 
                      is processing in IDMS`
                  } else if (validation.training_requests &&
                    role.name.toLowerCase() ===
                      validation.training_requests.toLowerCase()) {
                    allRoles[index].status = 'pending'
                    allRoles[index].message = `Selected user with role is
                    waiting for training check.`
                  }
                })
              }
            })
            this.setState({
              allRoles
            })
          })

        this.setState({
          formData
        })
      })
  }

  /* function will be called to get available roles when the project key selected from the options */
  changeKey = (value) => {
    if (value) {
      let username
      if (this.state.selectedvalue === 'Other') {
        username = this.state.formData.username
      } else {
        const {
          user
        } = this.context
        username = user.name
      }
      const key = value.key ? value.key : value.name
      const {
        formData
      } = this.state
      formData.projectkey = value.key
        ? `${value.key} - ${value.key_app_name}`
        : value.name
      formData.project = value
      if (this.state.formData.toolname.name === 'Cloudx Kubernetes') {
        requestAccess
          .getClustor(key)
          .then((response) => {
            this.setState({
              clustorArray: response.data
            })
          })
      } else {
        this.setState({
          loadingbar: true
        })
        requestAccess
          .getProjectRoles(this.state.formData.toolname.name,
            key,
            this.state.formData.toolname.is_key)
          .then((response) => {
            const allRoles = response.data
            requestAccess
              .GetRolesForProjectsValidation(username,
                this.state.formData.toolname.name,
                key,
                1)
              .then((response1) => {
                this.setState({
                  loadingbar: false
                })
                const validationdata = response1.data
                this.setState({
                  disableCheckBoxForQTest: false
                })
                allRoles.forEach((role, index) => {
                  if (validationdata.length) {
                    validationdata.forEach((validation) => {
                      if (validation.already_access &&
                        role.name.toLowerCase() ===
                          validation.already_access.toLowerCase()) {
                        allRoles[index].status = 'access'
                        allRoles[index].message = `Selected user already 
                          granted this role`
                      } else if (validation.cart_requests &&
                        role.name.toLowerCase() ===
                          validation.cart_requests.toLowerCase()) {
                        allRoles[index].status = 'cart'
                        allRoles[index].message = `Selected role with user 
                          already added to the cart by ${validation.cart_user}`
                      } else if (validation.pending_requests &&
                        role.name.toLowerCase() ===
                          validation.pending_requests.toLowerCase()) {
                        allRoles[index].status = 'pending'
                        allRoles[index].message = `Selected role with user 
                          is pending for approval`
                      } else if (validation.processing_request &&
                        role.name.toLowerCase() ===
                          validation.processing_request.toLowerCase()) {
                        allRoles[index].status = 'pending'
                        allRoles[index].message = `Selected role with user 
                          is processing in IDMS`
                      } else if (validation.training_requests &&
                        role.name.toLowerCase() ===
                          validation.training_requests.toLowerCase()) {
                        allRoles[index].status = 'pending'
                        allRoles[index].message = `Selected user with role is
                        waiting for training check.`
                      }
                      if (this.state.formData.toolname.name.toLowerCase() ===
                        'qtest' &&
                      ((validation.cart_requests &&
                        role.name.toLowerCase() ===
                          validation.cart_requests.toLowerCase()) ||
                      (validation.already_access &&
                        role.name.toLowerCase() ===
                          validation.already_access.toLowerCase()) ||
                      (validation.pending_requests &&
                        role.name.toLowerCase() ===
                          validation.pending_requests.toLowerCase()) ||
                      (validation.processing_request &&
                        role.name.toLowerCase() ===
                          validation.processing_request.toLowerCase()))) {
                        this.setState({
                          disableCheckBoxForQTest: true
                        })
                      }
                    })
                  }
                })
                this.setState({
                  allRoles
                })
              })
            if (!this.state.formData.toolname.is_key) {
              if (
                this.state.formData.toolname.name.toLowerCase() === 'applitools'
              ) {
                const username1 = `${username.toLowerCase()}@its.jnj.com`
                if (
                  this.state.formData.project.members &&
                this.state.formData.project.members[username1]
                ) {
                  allRoles.forEach((role, index) => {
                    if (
                      role.name.toLowerCase() === 'viewer' &&
                    this.state.formData.project.members[username1].isViewer
                    ) {
                      allRoles[index].status = 'access'
                      allRoles[index].message = `Selected user 
                        already granted access`
                    } else if (role.name.toLowerCase() === 'regular') {
                      allRoles[index].status = 'access'
                      allRoles[index].message = `Selected user 
                        already granted access`
                    }
                  })
                }
                this.setState({
                  allRoles
                })
              } else if (
                this.state.formData.toolname.name.toLowerCase() === 'blazemeter'
              ) {
                requestAccess
                  .checkUseraccessforAPItoolgroup(this.state.formData
                    .toolname.name,
                  username,
                  value.id)
                  .then((accessResponseForAPIToolGroup) => {
                    const {
                      data
                    } = accessResponseForAPIToolGroup
                    if (data.length && data[0].roles) {
                      allRoles.forEach((role, index) => {
                        if (
                          data[0].roles.indexOf(role.name.toLowerCase()) >
                        -1
                        ) {
                          allRoles[index].status = 'access'
                          allRoles[index].message =
                          'Selected user already granted access'
                        }
                      })
                    }
                    this.setState({
                      allRoles
                    })
                  },
                  (error) => {
                    if (error) {}
                    this.setState({
                      loadingbar: false,
                      failalertMessage:
                        'Something went wrong. Contact system administrator!',
                      errorAlert: true
                    })
                  })
              } else if (
                this.state.formData.toolname.name.toLowerCase() === 'saucelabs'
              ) {
                requestAccess
                  .checkUseraccessforAPItool(this.state.formData.toolname.name,
                    username)
                  .then((accessResponseAPITool) => {
                    const {
                      data
                    } = accessResponseAPITool
                    if (data.length && data[0].username) {
                      allRoles[0].status = 'access'
                      allRoles[0].message = `Selected user 
                        already granted access`
                    }
                    this.setState({
                      allRoles
                    })
                  },
                  (error) => {
                    if (error) {}
                    this.setState({
                      loadingbar: false,
                      failalertMessage:
                        'Something went wrong. Contact system administrator!',
                      errorAlert: true
                    })
                  })
              }
            }
            this.setState({
              formData
            })
          })
      }
    }
  }

  /* function will be called when form fields getting changed and used to update state with new values */
  handleChange = async (event) => {
    const {
      formData
    } = this.state
    formData[event.target.name] = event.target.value
    if (event.target.name === 'toolname') {
      /*  to clear the values of other fields when toolname is changed */
      formData.projectkey = ''
      formData.project = ''
      formData.org = ''
      formData.apigeeTeam = ''
      this.setState({
        allRoles: [],
        allProjects: [],
        selectedGroups: [],
        apigeeOrgs: [],
        apigeeOrgTeamsAndRoles: []
      })
      if (event.target.value.name === 'XENA Framework Tools') {
        this.changeKey({
          'key': 'XENA',
          'key_app_name': 'XENA',
          'key_prefix': 'asx'
        })
      }
    }
    this.setState({
      formData
    })

    if (
      event.target.name === 'toolname' &&
      this.state.formData.toolname.is_key === false &&
      this.state.formData.toolname.name !== 'Apigee'
    ) {
      /*    If the selected tool is API tool then the API will be called to get groups for pre populate */
      ToolsService
        .getApiToolGroups(this.state.formData.toolname.name)
        .then((response) => {
          this.setState({
            allProjects: response.data
          })
          if (response.data.length === 0) {
            this.changeKey({
              name: 'N/A'
            })
          }
        })
    }
    if (
      event.target.name === 'toolname' &&
      this.state.formData.toolname.name === 'Apigee'
    ) {
      ApigeeService.getApigeeOrgs().then((response) => {
        this.setState({
          apigeeOrgs: response.data
        })
      })
    }
    if (
      event.target.name === 'org' &&
      this.state.formData.toolname.name === 'Apigee'
    ) {
      ApigeeService.getTeamsForOrgs(event.target.value).then((response) => {
        formData.apigeeTeam = ''
        this.setState({
          apigeeOrgTeamsAndRoles: response.data,
          allRoles: [],
          formData
        })
      })
    }
    if (
      event.target.name === 'apigeeTeam' &&
      this.state.formData.toolname.name === 'Apigee'
    ) {
      let username
      if (this.state.selectedvalue === 'Other') {
        username = this.state.formData.username
      } else {
        const {
          user
        } = this.context
        username = user.name
      }
      const apigeeTeam = formData.apigeeTeam
      const teamsAndRoles = this.state.apigeeOrgTeamsAndRoles
      requestAccess
        .GetRolesForProjectsValidation(username,
          this.state.formData.toolname.name,
          apigeeTeam,
          1)
        .then((response1) => {
          for (const teamAndRoles of teamsAndRoles) {
            if (teamAndRoles.name === apigeeTeam) {
              const allRoles = teamAndRoles.roles
              const validationdata = response1.data
              allRoles.forEach((role, index) => {
                if (validationdata.length) {
                  validationdata.forEach((validation) => {
                    if (validation.already_access &&
                      role.name.toLowerCase() ===
                        validation.already_access.toLowerCase()) {
                      allRoles[index].status = 'access'
                      allRoles[index].message = `Selected user already 
                        granted this role`
                    } else if (validation.cart_requests &&
                      role.name.toLowerCase() ===
                        validation.cart_requests.toLowerCase()) {
                      allRoles[index].status = 'cart'
                      allRoles[index].message = `Selected role with user already
                         added to the cart by ${validation.cart_user}`
                    } else if (validation.pending_requests &&
                      role.name.toLowerCase() ===
                        validation.pending_requests.toLowerCase()) {
                      allRoles[index].status = 'pending'
                      allRoles[index].message = `Selected role with user 
                        is pending for approval`
                    } else if (validation.processing_request &&
                      role.name.toLowerCase() ===
                        validation.processing_request.toLowerCase()) {
                      allRoles[index].status = 'pending'
                      allRoles[index].message = `Selected role with user 
                        is processing in IDMS`
                    } else if (validation.training_requests &&
                      role.name.toLowerCase() ===
                        validation.training_requests.toLowerCase()) {
                      allRoles[index].status = 'pending'
                      allRoles[index].message = `Selected user with role is
                        waiting for training check.`
                    }
                  })
                }
              })
              allRoles.map(async (item, index) => {
                const result = await ApigeeService.getUsersForRole(this.state
                  .formData.org, item.name)
                const username1 = `${username.toLowerCase()}@its.jnj.com`
                if (result.data.length &&
                  result.data.toString().toLowerCase().includes(username1)) {
                  allRoles[index].status = 'access'
                  allRoles[index].message = `Selected user already 
                    granted this role`
                }
                this.setState({
                  allRoles
                })
              })
            }
          }
        })
    }
  }

  areAllTrainingsAssigned = (username) => {
    const trainingsReq = this.state.toAssign[username]
    const trainingsAssigned = this.state.assigned[username]
    if (!trainingsReq || !trainingsAssigned) {
      return false
    }
    const sortedTrainingsReq = trainingsReq.slice().sort()
    const sortedTrainingsAssigned = trainingsAssigned.slice().sort()
    let okay = false
    console.log(sortedTrainingsAssigned)
    console.log(sortedTrainingsReq)
    if (JSON.stringify(sortedTrainingsAssigned) ===
    JSON.stringify(sortedTrainingsReq)) {
      okay = true
    }
    return okay
  }

  handleAssign = (object) => {
    console.log(this.state.assigned)
    const key = object.learningString
    console.log(`Going to assign ${key.split('|')[1]} 
    to ${object.username}`)
    this.setState((prevState) => ({
      assignLoad: false,
      assigned: {
        ...prevState.assigned,
        [object.username]: prevState.assigned[object.username]
          ? prevState.assigned[object.username].includes(key)
            ? prevState.assigned[object.username]
            : [...prevState.assigned[object.username], key]
          : [key]
      }
    }))
  };

  handleAssignR = (object) => {
    console.log(this.state.assigned)
    const key = object.learningString
    console.log(`Going to remove ${key.split('|')[1]} from ${object.username}`)
    this.setState((prevState) => {
      const updatedUserAssignments = (prevState.assigned[object.username] || [])
        .filter((item) => item !== key)
      const newAssigned = {
        ...prevState.assigned,
        [object.username]: updatedUserAssignments
      }
      if (updatedUserAssignments.length === 0) {
        delete newAssigned[object.username]
      }
      return {
        assignLoad: false,
        assigned: newAssigned
      }
    })
  }

  assign = (object) => {
    if (this.areAllTrainingsAssigned(object.username)) {
      this.handleAssignAll(object, this.state.toAssign)
    } else {
      this.handleAssignAll(object, this.state.assigned)
    }
  }

  handleAssignAll = (object, source) => {
    console.log(source)
    const userArray = source[object.username]
    console.log(userArray)
    const promises = userArray.map((key) => {
      object.learningString = key
      console.log(`Going to assign ${key.split('|')[1]} to ${object.username}`)
      return requestAccess.assignTrainingInSummit(object).then((response) => {
        console.log(response)
        return response
      })
    })
    Promise.all(promises).then((responses) => {
      console.log('All responses:', responses)
      let canProceed = true
      responses.map((response) => {
        if (!response.data.isAssigned) {
          canProceed = false
        }
      })
      if (canProceed) {
        this.setState({
          assignLoad: false,
          summitModal: false,
          assignedModal: true
        })
      }
    }).catch((error) => {
      console.error('Error during assignment:', error)
      this.setState({
        assignLoad: false
      })
    })
  }

  handleAssignAllButton = (object) => {
    console.log(this.state.toAssign)
    const userArray = this.state.toAssign[object.username]
    console.log(userArray)
    for (let i = 0; i < userArray.length; i += 1) {
      const key = userArray[i]
      object.learningString = key
      console.log(`Going to assign ${key.split('|')[1]} 
      to ${object.username}`)
      this.setState((prevState) => {
        const currentAssignments = prevState.assigned[object.username] || []
        const updatedAssignments = currentAssignments.includes(key)
          ? currentAssignments
          : [...currentAssignments, key]
        return {
          assignLoad: false,
          assigned: {
            ...prevState.assigned,
            [object.username]: updatedAssignments
          }
        }
      })
    }
  };

  handleAssignAllButtonR = (object) => {
    this.setState({
      assignLoad: false,
      assigned: {
      }
    })
  };

  /* function to close the alert box  */
  alertClose = (event) => {
    this.setState({
      alert: false
    })
  }

  surveyJpmAlertClose = (event) => {
    this.setState({
      surveyJpmAlert: false,
      surveyJpmAlertMessage: ''
    })
  }

  errorAlertClose = () => {
    this.setState({
      errorAlert: false
    })
  }

  hasErrors = (array) => {
    for (let i = 0; i < array.length; i += 1) {
      const currentItem = array[i]
      if (!currentItem.hasCompleted) {
        return true
      }
    }
    return false
  }

  noRecords = (array) => {
    for (let i = 0; i < array.length; i += 1) {
      const currentItem = array[i]
      if (!currentItem.hasCompleted) {
        if (currentItem.noRecords) {
          return true
        }
      }
    }
    return false
  }

  gxpNotSet = (array) => {
    for (let i = 0; i < array.length; i += 1) {
      const currentItem = array[i]
      if (!currentItem.hasCompleted) {
        if (currentItem.gxpNotSet) {
          return true
        }
      }
    }
    return false
  }

  setTraining = (array) => {
    const assignObject = {
    }
    for (let i = 0; i < array.length; i += 1) {
      const object = array[i]
      if (object.error) {
        const userObject = {
        }
        const loids = []
        userObject.username = object.username
        Object.keys(object.error).map((key) => {
          console.log(key)
          loids.push(key)
        })
        if (assignObject[object.username]) {
          assignObject[object.username] = assignObject[object.username]
            .concat(loids)
        } else {
          assignObject[object.username] = loids
        }
      }
    }
    return assignObject
  }

  getHeight = () => {
    let numOfItems = 0
    this.state.summitResponses.map((item) => {
      if (!item.hasCompleted) {
        numOfItems += 1
      }
    })
    let req = numOfItems * 10
    if (numOfItems > 5) {
      req = 60
    }
    return req
  }

  handleBackdropClick = (event) => {
    event.stopPropagation()
  };

  handleErrorMessage = (responseArray) => {
    // let requestedRoles = ''
    // let trainingAssignedRole = ''
    // let erroMessage = 'Request is submitted for the group/s '
    // let targetArray = []
    // let requestedRole = []
    const groupedData = []
    const groupedByUserAndRole = {
    }
    responseArray.map((responseObject, index) => {
      console.log('response object ', responseObject)
      const username = responseObject.username
      const groupname = responseObject.role
      const trainingName = responseObject.trainingname
      const uniqueKey = `${username}-${groupname}`
      if (!groupedByUserAndRole[uniqueKey]) {
        groupedByUserAndRole[uniqueKey] = {
          userName: username,
          groupName: groupname,
          requestedRole: responseObject.trainingRoles,
          training: []
        }
      }
      groupedByUserAndRole[uniqueKey].training.push(trainingName)
      // if (!responseObject.hasCompleted) {
      //   if (responseArray.length - 1 === index) {
      //     trainingAssignedRole += `${responseObject.role}`
      //   } else {
      //     trainingAssignedRole += `${responseObject.role},`
      //   }
      //   const trainingNeedRoles = responseObject.trainingRoles
      //   targetArray = [...new Set([...targetArray, ...trainingNeedRoles])]
      //   if (index === 0) {
      //     requestedRole = responseObject.requestedRoleList
      //   }
      // }
    })
    for (const key in groupedByUserAndRole) {
      if (Object.prototype.hasOwnProperty.call(groupedByUserAndRole, key)) {
        groupedData.push(groupedByUserAndRole[key])
      }
    }
    // const finalTrainingRoles = []
    // targetArray.map((item) => {
    //   const matchedGroup = requestedRole.find((role) => role.includes(item))
    //   finalTrainingRoles.push(matchedGroup)
    // })
    // trainingAssignedRole = finalTrainingRoles.map((item) => `"${item}"`)
    //   .join(', ')
    // requestedRoles = requestedRole.map((item) => `"${item}"`).join(', ')
    // erroMessage += `<b>${requestedRoles}</b> ; For getting access to the group/s
    //   <b>${trainingAssignedRole}</b>, mandatory training needs to be completed.
    //    Once training is completed in Summit, request will be submitted
    //    for approval`
    return groupedData
  }

  /* function to submit the request */
  submitRequest = (input) => {
    const {
      user, setUser
    } = this.context
    let url = ''
    const inputClone = {
      ...input
    }
    console.log('inputClone ', inputClone)
    if (this.state.buttontype === 'addtocart') {
      url = 'addTocart'
    } else if (this.state.buttontype === 'submitnow') {
      url = 'checkTrainings'
      input = {
        'tool': input.requestType,
        'role': input.userRoles,
        'projectkey': input.projects,
        'username': input.crowdUsers,
        'comments': input.comments,
        'accessArea': 'byuser',
        'requestedBy': input.requestedBy
      }
    }
    this.setState({
      loadingbar: true
    })
    requestAccess[url](input).then((response) => {
      if (url === 'addTocart') {
        CartService.cartSize(user.name).then((cartdata) => {
          setUser({
            ...user,
            cartsize: cartdata.data.cartSize
          })
        })
        if (this.state.buttontype === 'addtocart') {
          this.setState({
            successalertMessage: 'Added to cart successfully'
          })
        }
        this.setState({
          successAlert: true
        }, () => {
          setTimeout(() => this.setState({
            successAlert: false
          }), 20000)
        })
      } else {
        console.log(input)
        console.log(response.data)
        console.log(this.state.buttontype)
        const array = response.data
        if (this.gxpNotSet(array)) {
          this.resetForm()
          this.setState({
            loadingbar: false,
            failalertMessage:
                  `GxP Flag is not set for this project,
                  Please contact System Administrator`,
            errorAlert: true
          })
        } else if (this.noRecords(array)) {
          this.resetForm()
          this.setState({
            loadingbar: false,
            failalertMessage:
                  `Training details are not available for this request,
                  Please contact System Administrator`,
            errorAlert: true
          })
        } else if (this.hasErrors(array)) {
          this.resetForm()
          this.setState({
            loadingbar: false,
            toAssign: this.setTraining(array),
            // summitResponses: array,
            pendingTraingList: this.handleErrorMessage(array),
            // trainingAlertMessage: this.handleErrorMessage(array),
            open: true
            // summitModal: true
          })
        } else {
          console.log('test ')
          let url = ''
          if (this.state.buttontype === 'submitnow') {
            url = 'submitRequestNow'
          }
          requestAccess[url](inputClone).then((response) => {
            LoginService.getAdminaccess().then((approvaldata) => {
              setUser({
                ...user,
                approvalcount: approvaldata.data.approvalcount
              })
            })
          })
          if (this.state.buttontype === 'submitnow') {
            this.setState({
              successalertMessage: 'Request Submitted Successfully'
            })
          }
          this.setState({
            successAlert: true
          }, () => {
            setTimeout(() => this.setState({
              successAlert: false
            }), 20000)
          })
          if (this.state.buttontype === 'submitnow' &&
             user.email && response.data.data.length) {
            EmailServices.sendMailtoApprover(response.data.data)
              .then((respone2) => { })
          }
        }
      }
      this.setState({
        loadingbar: false
      })
      this.resetForm()
      if (response.data.type === 'GitHub Copilot') {
        this.setState({
          githubAlert: true
          // githubCoPilotInfo: `This request once approved will activate
          //   GitHub Copilot license.
          //   For further onboarding details please refer to
          //   <a href="https://confluence.jnj.com/display/ABES/XENA+AI+Assisted+Software+Development"
          //     target=_blank> XENA AI Assisted Software Development page
          //   </a>.`
        }, () => {
          setTimeout(() => this.setState({
            githubAlert: false
          }), 20000)
        })
      }
    },
    (error) => {
      if (error) {}
      this.setState({
        loadingbar: false,
        failalertMessage:
            'Something went wrong. Contact system administrator!',
        errorAlert: true
      })
    })
  }

  /* function to reset the form */
  resetForm = (e) => {
    const formData = {
      email: '',
      password: '',
      toolname: '',
      projectkey: '',
      project: null,
      comments: '',
      username: '',
      usernameDisplay: '',
      clustor: ''
    }
    this.setState({
      formData,
      selectedGroups: [],
      allRoles: [],
      allProjects: [],
      errorAlert: false,
      clustorArray: [],
      disableCheckBoxForQTest: false,
      surveyValueRadio: '',
      surveyData: [],
      surveyDialog: false,
      surveyJpmAlert: false,
      surveyJpmAlertMessage: ''
    })
  }

  /* function to call after the alert box closed by clicking "Yes" button */
  confirmbutton = () => {
    const {
      user
    } = this.context
    this.setState({
      alertbox: false
    })
    let username
    if (this.state.selectedvalue === 'Other') {
      username = this.state.formData.username
    } else {
      username = user.name
    }
    const input = {
      requestedBy: user.name,
      crowdUsers: username,
      requestType: this.state.formData.toolname.name,
      userRoles: this.state.selectedGroups,
      comments: this.state.formData.comments,
      action_type: 1,
      projects: this.state.formData.project.key
        ? this.state.formData.project.key
        : this.state.formData.project.name
    }
    this.submitRequest(input)
  }

  /* to be called when the action buttons clicked */
  handleSubmit = (e) => {
    const {
      user
    } = this.context

    if (this.state.selectedGroups.length) {
      let username
      if (this.state.selectedvalue === 'Other') {
        username = this.state.formData.username
      } else {
        username = user.name
      }
      const input = {
        requestedBy: user.name,
        crowdUsers: username,
        requestType: this.state.formData.toolname.name,
        userRoles: this.state.selectedGroups,
        comments: this.state.formData.comments,
        action_type: 1,
        projects: this.state.formData.project.key
          ? this.state.formData.project.key
          : this.state.formData.project.name ||
          this.state.formData.apigeeTeam
      }

      requestAccess.checkGroupHierarchy(input).then((response) => {
        if (response.data === true) {
          this.submitRequest(input)
        } else {
          this.setState({
            alertboxMessage: response.data,
            alertbox: true
          })
        }
      })
    } else {
      this.setState({
        alertboxMessage: 'Please select at least one role',
        alert: true
      })
    }
  }

  /* function to get the projects list based on the search key */
  getProjects = (toolname, value) => {
    if (toolname.is_key) {
      this.setState({
        loadingoptions: true
      })
      requestAccess.getprojects(toolname.name, value).then((response) => {
        this.setState({
          allProjects: response.data,
          loadingoptions: false
        })
      })
    }
  }

  /* function to get the users list based on the search key */
  getUsers = (value) => {
    this.setState({
      loadingoptions_user: true
    })
    requestAccess.getallusersforautocomplete(value).then((response) => {
      this.setState({
        allUsers: response.data,
        loadingoptions_user: false
      })
    })
  }

  /* function to set the clicked button event as addtocart or submitnow */
  setType = (type, e) => {
    this.setState({
      buttontype: type
    })
  }

  /* to close the alert box */
  handleClose = () => {
    this.setState({
      alertbox: false
    })
  }

  handleSurveyValueChange = (type, value) => {
    if (type === 'text') {
      this.setState({
        surveyValueText: value
      })
    } else if (type === 'radio' && value && value.toLowerCase() === 'no') {
      this.setState({
        surveyValueRadio: value
      })
      SurveyService.getJpmDetails().then((storedSurveyReponse) => {
        this.setState({
          surveyJpmAlertMessage: storedSurveyReponse.data.data
        })
      })
    } else if (type === 'radio') {
      this.setState({
        surveyValueRadio: value
      })
    }
  }

  captureSurveyDetails = (event) => {
    event.preventDefault()
    if (this.state.surveyValueRadio.toLowerCase() === 'no') {
      this.setState({
        surveyJpmAlert: true
      })
    }
    const dataToStore = {
      surveyResponse: [
        {
          surveyId: this.state.surveyData.data[0].survey,
          surveyQuestion: this.state.surveyData.data[0].id,
          surveyResponse: this.state.surveyValueRadio
        }
      ]
    }
    if (this.state.surveyValueRadio === 'Yes') {
      dataToStore.surveyResponse.push({
        surveyId: this.state.surveyData.data[1].survey,
        surveyQuestion: this.state.surveyData.data[1].id,
        surveyResponse: this.state.surveyValueText
      })
    }
    this.setState({
      loadingbar: true
    })
    SurveyService.storeSurveyResponse(dataToStore)
      .then((storedSurveyReponse) => {
        this.setState({
          surveyDialog: false,
          loadingbar: false,
          surveyValueRadio: '',
          surveyData: []
        })
        if (storedSurveyReponse.data.status === 'success') {
          this.setState({
            successalertMessage: 'Survey Response is saved, ' +
            'Please proceed with the request',
            successAlert: true
          })
        } else if (storedSurveyReponse.data.status === 'failed') {
          this.setState({
            failalertMessage: 'Survey Response is not saved. ' +
            'Please inform the request details to your adimistrator ' +
            'before proceed with this request',
            errorAlert: true
          })
        } else {
          this.setState({
            failalertMessage: 'Something went wrong. Please contact system ' +
            'administrator with the request details',
            errorAlert: true
          })
        }
      })
  }

  /* function will be called when the roles selected by user to check whether the user can have access to raise request for this role */
  selectGroups = (role) => {
    const {
      selectedGroups
    } = this.state
    const idx = selectedGroups.indexOf(role.name)
    if (selectedGroups.indexOf(role.name) > -1) {
      selectedGroups.splice(idx, 1)
      this.setState({
        selectedGroups
      })
    } else if (this.state.formData.toolname.is_key) {
      const {
        user
      } = this.context
      let username
      if (this.state.selectedvalue === 'Other') {
        username = this.state.formData.username
      } else {
        username = user.name
      }

      const allRolesTemp1 = this.state.allRoles
      allRolesTemp1.map((role) => {
        if (role.name) {
          role.disableForSurvey = true
        }
      })
      this.setState({
        loadingbar: true,
        allRoles: allRolesTemp1
      })
      requestAccess
        .canAccountRequestAccessToGroup(this.state.formData.toolname.name,
          this.state.formData.project
            ? this.state.formData.project.key_prefix
            : this.state.formData.org,
          role.name,
          username,
          this.state.formData.project
            ? this.state.formData.project.key
            : this.state.formData.apigeeTeam)
        .then((response) => {
          this.setState({
            loadingbar: false
          })
          if (response.data[0].check) {
            selectedGroups.push(role.name)
            this.setState({
              loadingbar: true
            })
            const getSurveyData = {
              toolid: this.state.formData.toolname.toolid,
              role_name: role.name,
              project: this.state.formData.project
            }
            SurveyService.getSurveyQuestion(getSurveyData)
              .then((surveyResponse) => {
                if (
                  surveyResponse.data.status === 200 &&
                  surveyResponse.data.data &&
                  typeof surveyResponse.data.data !== 'string'
                ) {
                  this.setState({
                    surveyData: surveyResponse.data,
                    surveyDialog: true,
                    surveyValueText:
                      'XENA Teams Channel, XENA confluence page ...'
                  })
                }
                const allRolesTemp1 = this.state.allRoles
                allRolesTemp1.map((role) => {
                  if (role.name) {
                    role.disableForSurvey = false
                  }
                })
                this.setState({
                  loadingbar: false,
                  allRoles: allRolesTemp1
                })
              })
          } else {
            this.setState({
              ou: response.data[0]
            })
            const allRolesTemp1 = this.state.allRoles
            allRolesTemp1.map((role) => {
              if (role.name) {
                role.disableForSurvey = false
              }
            })
            this.setState({
              loadingbar: false,
              allRoles: allRolesTemp1
            })
            const message =
                this.state.ou !== null ? (
                  <div>
                    <Typography variant='subtitle2' gutterBottom>
                      Only users that belong to any one of the following
                      organization unit can request for this group
                    </Typography>
                    <ul>
                      {this.state.ou.roles.map((roleToShow) => (
                        <li>{roleToShow.ou}</li>
                      ))}
                    </ul>

                    <Typography variant='subtitle2' gutterBottom>
                      This account is a member of following Organization Unit:
                    </Typography>
                    <ul>
                      <li>{this.state.ou.user_roles}</li>
                    </ul>
                  </div>
                ) : null
            this.setState({
              alertboxMessage: message,
              alert: true
            })
          }
          this.setState({
            selectedGroups
          })
          if (this.state.formData.toolname.name.toLowerCase() === 'qtest' &&
            this.state.selectedGroups.length === 1) {
            this.setState({
              disableCheckBoxForQTest: true
            })
          }
        })
    } else {
      selectedGroups.push(role.name)
      this.setState({
        selectedGroups
      })
    }
  }

  /* to change the value of radio button for Self and Other */
  changeRadiotype = (value) => {
    this.resetForm()
    this.setState({
      selectedvalue: value
    })
  }

  selectQTestGroup = (value) => {
    this.setState({
      selectedGroups: [value.name]
    })
  }

  /* to get the available tools for tool selection dropdown when the page gets loaded */
  componentDidMount () {
    const values = queryString.parse(this.props.location.search)
    ToolsService.getAvailableTools('all').then((response) => {
      if (response.data) {
        const {
          formData
        } = this.state
        this.setState({
          alltools: response.data
        })
        if (values.tool) {
          const filtervalue = response.data
            .filter((item) => item.name.toLowerCase() ===
              values.tool.toLowerCase())
          if (filtervalue.length && filtervalue.length === 1) {
            /* eslint prefer-destructuring: ["error", {"array": false}] */
            formData.toolname = filtervalue[0]
            this.setState({
              formData
            })
            if (values.project) {
              requestAccess.getprojects(filtervalue[0].name, values.project)
                .then((projectResponse) => {
                //  this.setState({ allProjects: response.data, loadingoptions: false })
                  if (projectResponse.data.length &&
                    projectResponse.data.length === 1) {
                    this.changeKey(projectResponse.data[0])
                  }
                })
            }
          }
        }
      }
    })
  }

  render () {
    const {
      formData, loadingbar
    } = this.state
    const {
      classes
    } = this.props
    function getClassname (status) {
      switch (status) {
      case 'access':
        return classes.success
      case 'pending':
      case 'cart':
        return classes.pending
      default:
        return null
      }
    }
    function processString (str) {
      // Step 1: Remove the text within square brackets, including the brackets themselves
      str = str.replace(/\[.*?\]/g, '').trim()
      // Step 2: Remove the commas
      str = str.replace(/,/g, '').trim()
      // Step 3: Split the string into parts, swap them, and join back
      const parts = str.split(' ')
      if (parts.length > 1) {
        const firstName = parts[0]
        const lastName = parts[1]
        return `${lastName} ${firstName}`
      }
      return str
    }
    const {
      openModal,
      currentPage
    } = this.state
    const itemsPerPage = 2
    const lastIndex = currentPage * itemsPerPage
    const startIndex = lastIndex - itemsPerPage
    const currentItems = this.state.summitResponses.slice(startIndex, lastIndex)
    return (
      <div>
        {this.state.loadingbar ? <LinearProgress /> : null}
        {' '}
        <div className={classes.alertroot}>
          {' '}
          {this.state.successAlert ? (
            <Alert
              severity='success'
              onClose={() => this.setState({
                successAlert: false
              })}
            >
              {this.state.successalertMessage}
            </Alert>
          ) : null}
          {this.state.errorAlert ? (
            <Alert
              severity='error'
              onClose={() => this.errorAlertClose()}
            >
              {this.state.failalertMessage}
            </Alert>
          ) : null}
          {this.state.githubAlert ? (
            <Alert severity='info'>
              This request once approved will activate GitHub Copilot license.
              For further onboarding details please refer to
              <a href='https://confluence.jnj.com/display/ABES/XENA+AI+Assisted+Software+Development'>
                 XENA AI Assisted Software Development page
              </a>
            </Alert>
          ) : null}
        </div>
        {this.state.summitModal ? (
          <>
            <Modal
              BackdropProps={{
                onClick: this.handleBackdropClick
              }}
              open={this.state.summitModal}
              onClose={() => {
                if (Object.keys(this.state.assigned).length === 0) {
                  this.setState({
                    summitModal: false,
                    summitResponses: [],
                    toAssign: {
                    }
                  })
                } else {
                  this.setState({
                    summitModal: false,
                    summitResponses: [],
                    toAssign: {
                    },
                    assignedModal: true
                  })
                }
              }}
            >
              <div className={classes.modalStyle}>
                <div
                  className={classes.divStyle}
                >
                  <Typography variant='h6'>
                    Alert
                  </Typography>
                </div>
                <div>
                  <>
                    {this.state.assignLoad ? <LinearProgress /> : null}
                    <>
                      {parseHtml(this.state.trainingAlertMessage)}
                      {this.state.summitResponses.map((item) => (
                        <div
                          key={item.id}
                          className={classes.itemStyle}
                        >
                          {item.hasCompleted ? null : (
                            <>
                              <div>
                                {item.hasCompleted ? null : (
                                  <div>
                                    <div>
                                      {Object.keys(item.error)
                                        .map((key, i) => (
                                          <div
                                            style={{
                                              width: '25vw',
                                              display: 'flex',
                                              padding: '1px',
                                              alignItems: 'center'
                                            }}
                                          >
                                            <div
                                              style={{
                                                width: '20vw',
                                                textAlign: 'left'
                                              }}
                                            >
                                              <span>
                                                {key.split('|')[0]}
                                              </span>
                                            </div>
                                            <div
                                              style={{
                                                width: '3vw',
                                                alignContent: 'center',
                                                textAlign: 'center'
                                              }}
                                            >
                                              {this
                                                .state
                                                .assigned[item.username] &&
                                                this
                                                  .state
                                                  .assigned[item.username]
                                                  .includes(key)
                                                ? (
                                                  <Checkbox
                                                    checked={this.state
                                                      .assigned[item.username]
                                                      .includes(key)}
                                                    onChange={() => {
                                                      this.handleAssignR({
                                                        'learningString': key,
                                                        'username': item
                                                          .username
                                                      })
                                                    }}
                                                  />
                                                ) : (
                                                  <Checkbox
                                                    checked={false}
                                                    onChange={() => {
                                                      this.handleAssign({
                                                        'learningString': key,
                                                        'username': item
                                                          .username
                                                      })
                                                    }}
                                                  />
                                                )}
                                            </div>
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                      <div
                        style={{
                          width: '22vw',
                          marginLeft: '10px',
                          marginTop: '3vh',
                          display: 'flex',
                          justifyContent: 'right',
                          alignItems: 'center',
                          padding: '1px',
                          gap: '10px'
                        }}
                      >
                        {/* <Button
                          variant='contained'
                          color='primary'
                          size='small'
                          onClick={() => {
                            console.log(this
                              .areAllTrainingsAssigned(this.state
                                .summitResponses[0]
                                .username))
                            if (this
                              .areAllTrainingsAssigned(this.state
                                .summitResponses[0]
                                .username)) {
                              this.handleAssignAllButtonR({
                                'username': this.state
                                  .summitResponses[0]
                                  .username
                              })
                            } else {
                              this.handleAssignAllButton({
                                'username': this.state
                                  .summitResponses[0]
                                  .username
                              })
                            }
                          }}
                        >
                          Select All
                        </Button>
                        <Button
                          variant='contained'
                          color='secondary'
                          size='small'
                          onClick={() => {
                            if (Object.keys(this.state.assigned)
                              .length !== 0) {
                              this.setState({
                                assignLoad: true
                              })
                              this.assign({
                                'username': this.state
                                  .summitResponses[0]
                                  .username
                              })
                            }
                          }}
                        >
                          Submit
                        </Button> */}
                        <Button
                          variant='contained'
                          size='small'
                          disabled={this.state.assignLoad}
                          onClick={() => {
                            if (Object.keys(this.state.assigned)
                              .length === 0) {
                              this.setState({
                                summitModal: false,
                                summitResponses: [],
                                toAssign: {
                                },
                                assigned: {
                                }
                              })
                            } else {
                              this.setState({
                                summitModal: false,
                                summitResponses: [],
                                toAssign: {
                                },
                                assignedModal: true,
                                assigned: {
                                }
                              })
                            }
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </>
                  </>
                </div>
              </div>
            </Modal>
          </>
        ) : null}
        {Object.keys(this.state.assigned).length > 0 ? (
          <>
            <Modal
              BackdropProps={{
                onClick: this.handleBackdropClick
              }}
              open={this.state.assignedModal}
              onClose={() => this.setState({
                assignedModal: false,
                assigned: {
                }
              })}
            >
              <div className={classes.modalStyle}>
                <div
                  className={classes.divStyle}
                >
                  <Typography variant='h6'>
                    Alert
                  </Typography>
                </div>
                {Object.keys(this.state.assigned).map((key, index) => (
                  <>
                    <div
                      style={{
                        textAlign: 'center'
                      }}
                    >
                      User&nbsp;
                      {key}
                      &nbsp;is assigned with the following trainings in Summit.
                      Please re-submit the
                      request once these trainings are completed.
                      <br />
                      {this.state.assigned[key].map((val, index) => (
                        <div key={index}>
                          <b>
                            {val.split('|')[0]}
                          </b>
                        </div>
                      ))}
                    </div>
                  </>
                ))}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '5px'
                  }}
                >
                  <Button
                    variant='contained'
                    color='secondary'
                    size='small'
                    onClick={() => this.setState({
                      assignedModal: false,
                      assigned: {
                      }
                    })}
                    style={{
                      marginLeft: '2%',
                      marginRight: '1%'
                    }}
                  >
                    OK
                  </Button>
                </div>
              </div>
            </Modal>
          </>
        ) : null}
        <Grid container spacing={1} className={classes.root}>
          <Grid item xs={12} md={7}>
            <Card variant='outlined'>
              <ValidatorForm
                ref='form'
                onSubmit={(event) => this.handleSubmit(event)}
              >
                <CardContent>
                  <Typography variant='subtitle1' gutterBottom>
                    User Access Request
                  </Typography>
                  <div style={{
                    paddingTop: 10
                  }}
                  >
                    <RadioButtons
                      radio={this.state.radio}
                      selectedvalue={this.state.selectedvalue}
                      onChange={(value) => this.changeRadiotype(value)}
                    />
                    {this.state.selectedvalue === 'Other' ? (
                      <FormControl className={classes.formControl}>
                        <Autocomplete
                          id='combo-box-demo'
                          options={this.state.allUsers}
                          value={formData.usernameDisplay}
                          getOptionLabel={(option) => (option.name
                            ? `${option.name} - ${option.displayname}`
                            : option)}
                          loading={
                            this.state.allUsers.length === 0 &&
                            this.state.loadingoptions_user
                          }
                          getOptionSelected={
                            (option, value) => option.name === value.name
                          }
                          filterOptions={(options, state) => options}
                          onChange={(event, value) => this.changeUser(value)}
                          renderInput={(params) => (
                            <TextValidator
                              {...params}
                              label='Choose a user *'
                              name='username'
                              onChange={
                                (event) => this.getUsers(event.target.value)
                              }
                              value={formData.usernameDisplay}
                              size='small'
                              validators={['required']}
                              errorMessages={['This field is required']}
                            />
                          )}
                        />
                      </FormControl>
                    ) : null}
                    <FormControl className={classes.formControl}>
                      <SelectValidator
                        label='Tool'
                        name='toolname'
                        fullWidth
                        required
                        value={formData.toolname}
                        onChange={this.handleChange}
                        validators={['required']}
                        errorMessages={['This field is required']}
                      >
                        {this.state.alltools.map((value, index) => (
                          <MenuItem value={value} key={value.name}>
                            {value.name}
                          </MenuItem>
                        ))}
                      </SelectValidator>
                    </FormControl>
                    {formData.toolname.name === 'Apigee' ? (
                      <FormControl className={classes.formControl}>
                        <SelectValidator
                          label='Org'
                          name='org'
                          fullWidth
                          required
                          onChange={this.handleChange}
                          value={formData.org}
                          validators={['required']}
                          errorMessages={['This field is required']}
                        >
                          {this.state.apigeeOrgs.map((value, index) => (
                            <MenuItem value={value} key={value}>
                              {value}
                            </MenuItem>
                          ))}
                        </SelectValidator>
                      </FormControl>
                    ) : null}
                    {formData.toolname.name === 'Apigee' ? (
                      <FormControl className={classes.formControl}>
                        <SelectValidator
                          label='Apigee Team'
                          name='apigeeTeam'
                          required
                          fullWidth
                          onChange={this.handleChange}
                          value={formData.apigeeTeam}
                          validators={['required']}
                          errorMessages={['This field is required']}
                        >
                          {this.state.apigeeOrgTeamsAndRoles.map((value,
                            index) => (
                            <MenuItem value={value.name} key={value.name}>
                              {value.name}
                            </MenuItem>
                          ))}
                        </SelectValidator>
                      </FormControl>
                    ) : null}
                    {formData.projectkey !== 'N/A' &&
                    formData.toolname.name !== 'Apigee' &&
                      formData.toolname !== 'Saucelabs' ? (
                        <FormControl className={classes.formControl}>
                          <Autocomplete
                            id='combo-box-demo'
                            options={this.state.allProjects}
                            value={formData.projectkey}
                            getOptionLabel={(option) => (option.key
                              ? `${option.key} - ${option.key_app_name}`
                              : option.name
                                ? option.name
                                : option)}
                            loading={
                              this.state.allProjects.length === 0 &&
                              this.state.loadingoptions
                            }
                            getOptionSelected={
                              (option, value) => option.name === value.name
                            }
                            onChange={
                              (event, value) => this.changeKey(value)
                            }
                            renderInput={(params) => (
                              <TextValidator
                                {...params}
                                label='Project or Team *'
                                name='projectkey'
                                onChange={
                                  (event) => this.getProjects(this.state
                                    .formData.toolname,
                                  event.target.value)
                                }
                                value={formData.projectkey}
                                validators={['required']}
                                errorMessages={['This field is required']}
                              />
                            )}
                          />
                        </FormControl>
                      ) : null}
                    {this.state.formData.toolname.name ===
                      'Cloudx Kubernetes' &&
                      this.state.clustorArray.length === 0 &&
                      this.state.formData.project.key
                      ? (
                        <span style={{
                          color: 'red'
                        }}
                        >
                          This project do not have any cluster
                        </span>
                      ) : null}
                    {this.state.formData.toolname.name ===
                      'Cloudx Kubernetes' &&
                      this.state.clustorArray.length > 0
                      ? (
                        <FormControl className={classes.formControl}>
                          <SelectValidator
                            label='Cluster'
                            name='clustor'
                            fullWidth
                            value={formData.clustor}
                            onChange={this.changeClustor}
                            validators={['required']}
                            errorMessages={['This field is required']}
                          >
                            {this.state.clustorArray.map((value, index) => (
                              <MenuItem value={value} key={value.clustor}>
                                {value.clustor}
                              </MenuItem>
                            ))}
                          </SelectValidator>
                        </FormControl>
                      )
                      : null}

                    <FormControl className={classes.formControl}>
                      <TextValidator
                        label='Reason for Requesting Access*'
                        name='comments'
                        fullWidth
                        onChange={this.handleChange}
                        value={formData.comments}
                        multiline
                        rows={1}
                        rowsMax={4}
                        validators={['required']}
                        errorMessages={['This field is required']}
                      />
                    </FormControl>
                  </div>
                </CardContent>
                <CardActions className={classes.footer}>
                  {/* Info Button and Dialog */}
                  <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                    maxWidth='lg'
                    fullWidth='lg'
                    style={{
                      maxHeight: '600px',
                      marginLeft: '250px',
                      marginRight: '250px',
                      overflowY: 'auto'
                    }}
                  >
                    <DialogTitle id='alert-dialog-title'>
                      {globalData.summitWarning}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Requested for</TableCell>
                              <TableCell>Group Name</TableCell>
                              <TableCell>Training Details</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.pendingTraingList.map((user, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  {user.userName}
                                </TableCell>
                                <TableCell>
                                  {user.requestedRole.join(', ')}
                                </TableCell>
                                <TableCell>
                                  {user.training.join(', ')}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.handleCloseTable} color='primary'>
              Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                  {/* Info Button and Dialog */}
                  <Button
                    variant='contained'
                    size='small'
                    onClick={this.resetForm.bind(this)}
                  >
                    Reset
                  </Button>
                  <Button
                    variant='contained'
                    color='primary'
                    type='submit'
                    size='small'
                    disabled={loadingbar || this.checkSwitchUser()}
                    onClick={this.setType.bind(this, 'addtocart')}
                  >
                    Add to Cart
                  </Button>
                  <Button
                    variant='contained'
                    color='secondary'
                    type='submit'
                    disabled={loadingbar || this.checkSwitchUser()}
                    size='small'
                    disableRipple
                    onClick={this.setType.bind(this, 'submitnow')}
                  >
                    Submit Now
                  </Button>
                </CardActions>
              </ValidatorForm>
            </Card>
          </Grid>
          {this.state.allRoles.length ? (
            <Grid item xs={12} md={5}>
              <Card variant='outlined'>
                <CardContent>
                  {' '}
                  <Typography variant='subtitle1' gutterBottom>
                    Select Roles
                  </Typography>
                  <List component='nav' aria-label='main mailbox folders'>
                    {this.state.allRoles.map((role) => (
                      <ListItem
                        key={role.name ? role.name : role}
                        className={getClassname(role.status)}
                      >
                        <FormControlLabel
                          control={
                            this.state.formData
                              .toolname.name.toLowerCase() === 'qtest'
                              ? (
                                <Radio
                                  value={role}
                                  label={role}
                                  key={role}
                                  onChange={
                                    this.selectQTestGroup.bind(this, role)
                                  }
                                  disabled={
                                    (
                                      role.status === 'access' ||
                                  role.status === 'pending' ||
                                  role.status === 'cart' ||
                                  role.disableForSurvey
                                    ) || (
                                      this.state.disableCheckBoxForQTest
                                    )
                                  }
                                  checked={
                                    role.status
                                      ? true
                                      : this.state.selectedGroups
                                        .indexOf(role.name) > -1
                                  }
                                />
                              )
                              : (
                                <Checkbox
                                  name='checkedA'
                                  disabled={
                                    (
                                      role.status === 'access' ||
                                  role.status === 'pending' ||
                                  role.status === 'cart' ||
                                  role.disableForSurvey
                                    ) || (
                                      this.state.disableCheckBoxForQTest
                                    )
                                  }
                                  value={role}
                                  checked={
                                    role.status
                                      ? true
                                      : this.state.selectedGroups
                                        .indexOf(role.name) > -1
                                  }
                                  onChange={this.selectGroups.bind(this, role)}
                                />
                              )
                          }
                          label={role.name}
                        />
                        {role.status === 'access' ||
                          role.status === 'pending' ||
                          role.status === 'cart' ? (
                            <Tooltip
                              title={(
                                <span style={{
                                  fontSize: 12,
                                  fontFamily: 'Verdana'
                                }}
                                >
                                  {role.message}
                                </span>
                              )}
                            >
                              <InfoIcon color='action' fontSize='small' />
                            </Tooltip>
                          ) : null}
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          ) : null}
          <AlertDialog
            handleClose={this.handleClose}
            alertopen={this.state.alertbox}
            message={this.state.alertboxMessage}
            confirmbutton={this.confirmbutton}
            key='alert1'
            title='Alert'
            okbuttonalert={false}
          />
          <AlertDialog
            handleClose={this.alertClose}
            alertopen={this.state.alert}
            key='alert2'
            message={this.state.alertboxMessage}
            okbuttonalert
            title='Alert'
          />
          <AlertDialog
            handleClose={this.surveyJpmAlertClose}
            alertopen={this.state.surveyJpmAlert}
            key='alert3'
            message={parseHtml(this.state.surveyJpmAlertMessage)}
            okbuttonalert
            title='JPM Info'
          />
        </Grid>
        {/* Dialog Box for Change Approver */}
        <Dialog
          open={this.state.surveyDialog}
          maxWidth='xs'
          fullWidth='xs'
        >
          {this.state.alertLoading ? <LinearProgress /> : null}
          <ValidatorForm
            ref='form'
            onSubmit={(event) => this.captureSurveyDetails(event)}
          >
            <DialogTitle id='alert-dialog-title'>Survey</DialogTitle>
            <DialogContent>
              <div
                style={{
                  paddingTop: 10
                }}
              >
                {this.state.surveyData &&
                this.state.surveyData.data &&
                this.state.surveyData.data.length > 0 ? (
                    <FormControl
                      className={`${classes.formControl}
                          ${classes.columnDisplay}`}
                    >
                      <Typography
                        htmlFor='workflowTypeLabel'
                        className={classes.labelDisplay}
                      >
                        {this.state.surveyData &&
                          this.state.surveyData.data &&
                          this.state.surveyData.data.length > 0 &&
                          this.state.surveyData.data[0].survey_question}
                      </Typography>
                      <RadioButtons
                        className={classes.inputDisplay}
                        radio={['Yes', 'No']}
                        selectedvalue={this.state.surveyValueRadio}
                        onChange={(value) => this
                          .handleSurveyValueChange('radio', value)}
                      />
                    </FormControl>
                  ) : null}
                {this.state.surveyData &&
                this.state.surveyData.data &&
                this.state.surveyData.data.length > 1 &&
                this.state.surveyValueRadio === 'Yes' &&
                this.state.surveyValueRadio !== '' ? (
                    <FormControl
                      className={`${classes.formControl}
                      ${classes.columnDisplay}`}
                    >
                      <Typography
                        htmlFor='workflowTypeLabel'
                        className={classes.labelDisplay}
                      >
                        {this.state.surveyData &&
                          this.state.surveyData.data &&
                          this.state.surveyData.data.length &&
                          this.state.surveyData.data.length > 1 &&
                          this.state.surveyData.data[1].survey_question}
                      </Typography>
                      <FormControl className={classes.formControl}>
                        <TextValidator
                          label='Place your answers*'
                          name='survey1_answers'
                          onChange={(e) => this
                            .handleSurveyValueChange('text', e.target.value)}
                          value={this.state.surveyValueText}
                          multiline
                          rows={2}
                          fullWidth
                          rowsMax={10}
                          validators={['required']}
                          errorMessages={['This field is required']}
                        />
                      </FormControl>
                    </FormControl>
                  ) : null}
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                variant='contained'
                color='primary'
                type='submit'
                disabled={
                  this.state.loadingbar ||
                  this.checkSwitchUser() ||
                  (this.state.surveyValueRadio === 'Yes' &&
                    this.state.surveyValueText === '') ||
                  this.state.surveyValueRadio === ''
                }
              >
                Submit
              </Button>
            </DialogActions>
          </ValidatorForm>
        </Dialog>
      </div>
    )
  }
}
ByUserComponent.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ByUserComponent)
