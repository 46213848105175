import React, {
  useContext, useState, useRef, useEffect
} from 'react'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Grid,
  makeStyles,
  FormControl,
  LinearProgress,
  Checkbox,
  Tooltip,
  FormControlLabel,
  MenuItem,
  FormGroup,
  ListItemText,
  Input,
  FormHelperText,
  IconButton
} from '@material-ui/core'
import {
  useParams, Link
} from 'react-router-dom'
import {
  TextValidator, ValidatorForm, SelectValidator
} from 'react-material-ui-form-validator'
import {
  Autocomplete, Alert
} from '@material-ui/lab'
import InfoIcon from '@material-ui/icons/Info'
import RadioButtons from '../inputs/radio'
import MultipleSelect from '../inputs/multipleselectbox'
import SupportService from '../../services/api/supportService'
import requestAccess from '../../services/api/requestaccessService'
import UserContext from '../contexts/UserContext'
import AlertDialog from '../alertbox/alertboxComponent'
import SupportContext from '../contexts/SupportContext'
import PathContext from '../contexts/PathContext'
import SupportComponent from './supportComponent'
import helpicon from '../../assets/images/knowledgebase.jpg'
import ToolsService from '../../services/api/toolsService'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%'
  },
  alertroot: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  },
  pullRight: {
    float: 'right'
  },
  columnDisplay: {
    display: 'flex',
    flexDirection: 'row'
  },
  labelDisplay: {
    margin: '10px',
    width: '25%'
  },
  inputDisplay: {
    width: '72%'
  },
  inputDisplayQTId: {
    width: '42%'
  },
  inputDisplayQTOId: {
    paddingLeft: '2%',
    width: '30%'
  },
  error: {
    color: 'red'
  },
  errorMessage: {
    paddingLeft: '27%',
    width: '70%'
  },
  centerText: {
    textAlign: 'center',
    fontSize: '14px',
    color: '#737373'
  },
  hr: {
    display: 'block',
    marginTop: '0.5em',
    marginBottom: '0.5em',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderStyle: 'inset',
    borderWidth: '1px',
    borderColor: '#737373'
  },
  dashedSeparator: {
    border: '1px dashed #737373 !important'
  }
}))

export default function SupportCreationTicketComponent (props) {
  const {
    setPath
  } = useContext(PathContext)
  const {
    user
  } = useContext(UserContext)
  const {
    support, setSupport
  } = useContext(SupportContext)
  const {
    type
  } = useParams()
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  const form = useRef(null)
  const [workflow, setWorkflow] = useState([])
  const [applicationId, setApplicationId] = useState([])
  const [refRadioValue, setRefRadioValue] = useState('No')
  const [qTesteJiraRadioValue, setQTestJiraRadioValue] = useState('No')
  const [workflowRadioValue, setWorkflowRadioValue] = useState('Other')
  const [ownerRadioValue, setOwnerRadioValue] = useState('No')
  const [soxRadioValue, setSoxRadioValue] = useState('No')
  const [allUsers, setAllUsers] = useState([])
  const [loadingOptions, setLoadingOptions] = useState(false)
  const [allInstanceId, setAllInstanceId] = useState([])
  const [allSow, setAllSow] = useState([])
  const [tools, setTools] = useState([])
  const [allProjects, setAllProjects] = useState([])
  const [alertwithok, setAlertwithok] = React.useState(false)
  const [alertboxMessage, setalertboxMessage] = React.useState('')
  const [artifactoryPackages, setArtifactoryPackages] = React.useState([])
  const [showOtherPackage, setShowOtherPackage] = React.useState(false)
  const [artifactoryError, setArtifactoryError] = React.useState(false)
  const [qTestAppIdDisable, setQTestAppIdDisable] = React.useState(false)
  const [qTestAppIdNotListedDisable, setQTestAppIdNotListedDisable] = React
    .useState(false)

  // To Show Project Name field
  const showProjectNameField = () => {
    switch (type) {
    case 'confluence':
      return 'Project or Space Name'
    case 'jenkins':
      return 'Project or Team Name'
    default:
      return 'Project Name'
    }
  }

  // Returns alert and it's message
  const showAlert = () => {
    if (support.success === false && support.message !== '') {
      return (
        <Alert
          severity='error'
          onClose={() => setSupport({
            success: false,
            message: ''
          })}
        >
          {support.message}
        </Alert>
      )
    }
  }

  useEffect(() => {
    const tools1 = [{
      value: 'jira',
      name: 'Jira'
    }, {
      value: 'confluence',
      name: 'Confluence'
    }, {
      value: 'bitbucket',
      name: 'Bitbucket'
    }, {
      value: 'jenkins',
      name: 'Jenkins'
    }, {
      value: 'artifactory',
      name: 'Artifactory'
    }, {
      value: 'qtest',
      name: 'qTest'
    }]
    ToolsService.getEnabledTools().then((response) => {
      const toolsresponse = []
      tools1.map((item1) => {
        if (response.data.find((item) => item.name.includes(item1.name))) {
          toolsresponse.push(item1)
        }
      })
      setTools(toolsresponse)
    })
    const getDataPromise = (value) => new Promise((resolve, reject) => {
      SupportService.checkJnJEmployee(value).then((response) => {
        resolve(response.data)
      })
    })
    ValidatorForm.addValidationRule('checkJnJEmployee', async (value) => {
      if (value) {
        const result = await getDataPromise(value)
        return result
      }
      return true
    })
  }, [])

  // Initializing form data
  const [formData, setFormData] = useState({
    requestFor: user.name.toUpperCase(),
    investmentID: '',
    sowNo: '',
    accountOwner: '',
    accountOwnerDisplay: '',
    jiraProjectLead: '',
    confluenceSpaceAdmin: '',
    qTestAdmin: '',
    jenkinsTeamLead: '',
    artifactoryProjectLead: '',
    repoAdmin: '',
    referenceProject: '',
    jiraReferenceProject: '',
    jenkinsReferenceProject: '',
    artifactoryReferenceProject: '',
    qTreferenceProject: '',
    qTApplicationIdValue: '',
    otherQTApplicationID: 'NA',
    notListedApplicationID: false,
    projectName: '',
    needBitbucket: false,
    needXray: false,
    workflowScheme: '',
    gxp: 'No',
    additonalInfo: '',
    needAccountOwnerInProject: 'No',
    soxCompliant: 'No',
    bbProjectKey: '',
    jenkinsProjectType: ['Development'],
    artifactoryProjectType: [],
    cicdProjectExists: false,
    artifactoryProjectExists: false,
    jenkinsProjectExists: false,
    otherArtifactoryPackage: '',
    selectedTools: []
  })
  const [allSelected, setAllSelected] = useState(false)

  // Jenkins Project Type
  const jenkinsProjectTypes = [{
    value: 'Development'
  }, {
    value: 'Release'
  }]

  // const tools = [{
  //   value: 'jira',
  //   name: 'Jira'
  // }, {
  //   value: 'confluence',
  //   name: 'Confluence'
  // }, {
  //   value: 'bitbucket',
  //   name: 'Bitbucket'
  // }, {
  //   value: 'jenkins',
  //   name: 'Jenkins'
  // }, {
  //   value: 'artifactory',
  //   name: 'Artifactory'
  // }, {
  //   value: 'qtest',
  //   name: 'qTest'
  // }]

  const handleToolSelection = (e) => {
    if (e.target.checked) {
      if (e.target.value === 'alltool') {
        setAllSelected(true)
        tools.map((it, i) => {
          formData.selectedTools.push(it.value)
        })
      } else {
        setAllSelected(false)
        formData.selectedTools.push(e.target.value)
      }
      setFormData({
        ...formData,
        selectedTools: formData.selectedTools
      })
      if (formData.selectedTools.includes('jira')) {
        setLoading(true)
        SupportService.getWorkflows(workflowRadioValue.toLowerCase())
          .then((response) => {
            setLoading(false)
            if (response.data) {
              setWorkflow(response.data)
              setFormData({
                ...formData,
                workflowScheme: response.data[0]
              })
            }
          })
      }
      if (formData.selectedTools.includes('qtest')) {
        SupportService.getApplicationId().then((response) => {
          setApplicationId(response.data)
        })
      }
      if (formData.selectedTools.includes('artifactory')) {
        setLoading(true)
        SupportService.getArtifactoryPackages().then((response) => {
          setLoading(false)
          if (response.data) {
            setArtifactoryPackages(response.data.packages)
          }
        })
      }
    } else if (!e.target.checked) {
      if (e.target.value === 'alltool') {
        setAllSelected(false)
        formData.selectedTools = []
      } else {
        const removeItem = formData.selectedTools.indexOf(e.target.value)
        if (removeItem !== -1) {
          formData.selectedTools.splice(removeItem, 1)
        }
      }

      setFormData({
        ...formData,
        selectedTools: formData.selectedTools
      })
      if (e.target.value === 'jira') {
        setFormData({
          ...formData,
          workflowScheme: '',
          needXray: false
        })
      }
      if (e.target.value === 'bitbucket') {
        setFormData({
          ...formData,
          repoAdmin: ''
        })
      }
      if (e.target.value === 'jenkins') {
        setFormData({
          ...formData,
          jenkinsProjectType: ['Development'],
          jenkinsTeamLead: '',
          cicdProjectExists: false,
          referenceProject: ''
        })
      }
      if (e.target.value === 'artifactory') {
        setFormData({
          ...formData,
          artifactoryProjectType: [],
          otherArtifactoryPackage: '',
          cicdProjectExists: '',
          referenceProject: ''
        })
      }
    }
  }

  // Reference project change option
  const refChangeOption = (value) => {
    setRefRadioValue(value)
    if (value === 'No') {
      setWorkflowRadioValue('Other')
      workflowChangeOption(workflowRadioValue)
    }
  }
  const qTrefChangeOption = (value) => {
    setQTestJiraRadioValue(value)
  }

  // Handling change for the fields
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const handleGxpChange = (value) => {
    setFormData({
      ...formData,
      gxp: value
    })
    if (value.toLowerCase() === 'yes' &&
      !formData.selectedTools.includes('qtest')) {
      setLoading(true)
      SupportService.getGxpAlertMessage().then((response) => {
        setLoading(false)
        setalertboxMessage(response.data.alertMessage)
        setAlertwithok(true)
      })
    }
    if (value.toLowerCase() === 'yes' &&
      formData.selectedTools.includes('qtest') &&
      !formData.selectedTools.includes('jira')) {
      setQTestAppIdDisable(true)
      setLoading(true)
      SupportService.getQTestAlertMessage().then((response) => {
        setLoading(false)
        setalertboxMessage(response.data.alertMessage)
        setAlertwithok(true)
        setFormData({
          ...formData,
          otherQTApplicationID: 'NA',
          gxp: value
        })
      })
    }
    if (value.toLowerCase() === 'yes' &&
      formData.selectedTools.includes('qtest') &&
      formData.selectedTools.includes('jira')) {
      setQTestAppIdDisable(true)
      setLoading(true)
      SupportService.getGxpAlertMessage().then((xrayAlertResponse) => {
        SupportService.getQTestAlertMessage().then((qTestAlertResponse) => {
          setLoading(false)
          setalertboxMessage(`For Jira project '
            ${xrayAlertResponse.data.alertMessage}' \n 
            For qTest project ${qTestAlertResponse.data.alertMessage}`)
          setAlertwithok(true)
          setFormData({
            ...formData,
            otherQTApplicationID: 'NA',
            gxp: value
          })
        })
      })
    }
    if (value.toLowerCase() === 'no' &&
      formData.selectedTools.includes('qtest')) {
      setQTestAppIdDisable(false)
      setFormData({
        ...formData,
        otherQTApplicationID: '',
        gxp: value
      })
    }
  }

  // Handling change for need bitbucket option
  const handleNeedBBChange = (e) => {
    if (e.target.checked === false) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.checked,
        repoAdmin: ''
      })
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.checked
      })
    }
  }

  const handleQTNotListedAppId = (e) => {
    if (e.target.checked === true) {
      setQTestAppIdNotListedDisable(true)
      setApplicationId([])
      setFormData({
        ...formData,
        notListedApplicationID: e.target.checked,
        qTApplicationIdValue: 'Others'
      })
    } else {
      setQTestAppIdNotListedDisable(false)
      setFormData({
        ...formData,
        notListedApplicationID: e.target.checked,
        qTApplicationIdValue: ''
      })
    }
  }

  const handleNeedXrayChange = (e) => {
    if (e.target.checked === true) {
      setLoading(true)
      SupportService.getXrayAlertMessage().then((response) => {
        setLoading(false)
        setFormData({
          ...formData,
          needXray: true
        })
        setalertboxMessage(response.data.alertMessage)
        setAlertwithok(true)
      })
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.checked
      })
    }
  }

  const handleCICDExists = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked
    })
  }

  // Handling Jenkins Project Type
  const handleJenkinsType = (event) => {
    const array = formData.jenkinsProjectType
    if (event.target.value.toLowerCase() === 'development' &&
      !array.includes('Development')) {
      array.push(event.target.value)
    } else if (event.target.value.toLowerCase() === 'development' &&
      array.includes('Development')) {
      const index = array.indexOf('Development')
      if (index > -1) {
        array.splice(index, 1)
      }
    } else if (event.target.value.toLowerCase() === 'release' &&
      !array.includes('Release')) {
      array.push(event.target.value)
    } else {
      const index = array.indexOf('Release')
      if (index > -1) {
        array.splice(index, 1)
      }
    }
    setFormData({
      ...formData,
      jenkinsProjectType: array
    })
  }

  // Handling Artifactory Project Type
  const handlingArtifactoryType = (event) => {
    setFormData({
      ...formData,
      artifactoryProjectType: event.target.value
    })
    event.target.value.map((value) => {
      if (value === 'Other') {
        setShowOtherPackage(true)
      } else {
        setShowOtherPackage(false)
      }
    })
  }

  // Handling on workflow option change
  const workflowChangeOption = (value) => {
    setLoading(true)
    setWorkflowRadioValue(value)
    SupportService.getWorkflows(value.toLowerCase()).then((response) => {
      setLoading(false)
      if (response.data) {
        setWorkflow(response.data)
        setFormData({
          ...formData,
          workflowScheme: response.data[0]
        })
      }
    })
  }

  // Handling on need account owner option
  const ownerChangeOption = (value) => {
    setOwnerRadioValue(value)
    setFormData({
      ...formData,
      needAccountOwnerInProject: value
    })
  }

  // Get Users for auto complete
  const getUsers = (username) => {
    setFormData({
      ...formData,
      searchkey: username
    })
    setLoadingOptions(true)
    requestAccess.getallusersforautocomplete(username).then((response) => {
      setLoadingOptions(false)
      setAllUsers(response.data)
    })
  }

  // Get instantis id
  const getInstanceId = (value) => {
    setFormData({
      ...formData,
      searchkey: value
    })
    setLoadingOptions(true)
    SupportService.getInvestmentID(value).then((response) => {
      setLoadingOptions(false)
      setAllInstanceId(response.data)
    })
  }

  // Handling change for the user name in fields
  const changeUser = (value) => {
    if (value) {
      formData.requestFor = `${value.name} - ${value.displayname
        ? value.displayname
        : null}`
      formData.requestForName = value.name
      setFormData({
        ...formData
      })
    } else {
      formData.requestFor = ''
      formData.requestForName = ''
      setFormData({
        ...formData
      })
    }
    setAllUsers([])
  }

  // Handling change for account owner field
  const aoChangeUser = (value) => {
    if (value) {
      formData.accountOwner = value.name
      formData.accountOwnerDisplay = `${value.name} - ${value.displayname
        ? value.displayname
        : null}`
      setFormData({
        ...formData
      })
    } else {
      formData.accountOwner = ''
      formData.accountOwnerDisplay = ''
      setFormData({
        ...formData
      })
    }
    setAllUsers([])
  }

  // Handling change for repo admin field
  const repoAdminChangeUser = (value) => {
    if (value) {
      setFormData({
        ...formData,
        repoAdmin: `${value.name} - ${value.displayname}`
      })
    }
    setAllUsers([])
  }

  // Handling change for project lead field
  const jiraPLChangeUser = (value) => {
    if (value) {
      let samePL = formData.confluenceSpaceAdmin
      if (!formData.confluenceSpaceAdmin &&
        formData.confluenceSpaceAdmin === '') {
        samePL = `${value.name} - ${value.displayname
          ? value.displayname
          : null}`
      }
      formData.jiraProjectLead = `${value.name} - ${value.displayname
        ? value.displayname
        : null}`
      formData.confluenceSpaceAdmin = samePL
      setFormData({
        ...formData
      })
    } else {
      formData.jiraProjectLead = ''
      formData.confluenceSpaceAdmin = ''
      setFormData({
        ...formData
      })
    }
    setAllUsers([])
  }

  // Clearing text in autocomplete fields
  const clearText = (field, userValue, reason) => {
    if (!userValue && reason === 'reset') {
      setFormData({
        ...formData,
        [field]: ''
      })
    }
  }

  // Handling change for confluence space admin field
  const confluenceSAChangeUser = (value) => {
    if (value) {
      let sameConfAdmin = formData.jiraProjectLead
      if (!formData.jiraProjectLead && formData.jiraProjectLead === '') {
        sameConfAdmin = `${value.name} - ${value.displayname
          ? value.displayname
          : null}`
      }
      setFormData({
        ...formData,
        confluenceSpaceAdmin: `${value.name} - ${value.displayname
          ? value.displayname
          : null}`,
        jiraProjectLead: sameConfAdmin
      })
    }
    setAllUsers([])
  }

  const qTestAdminChange = (value) => {
    if (value) {
      setFormData({
        ...formData,
        qTestAdmin: `${value.name} - ${value.displayname
          ? value.displayname
          : null}`
      })
    }
    setAllUsers([])
  }

  const jenkinsTLChangeUser = (value) => {
    if (value) {
      let sameTL = formData.jenkinsTeamLead
      if (!formData.artifactoryProjectLead &&
        formData.artifactoryProjectLead === '') {
        sameTL = `${value.name} - ${value.displayname
          ? value.displayname
          : null}`
      }
      setFormData({
        ...formData,
        jenkinsTeamLead: `${value.name} - ${value.displayname
          ? value.displayname
          : null}`,
        artifactoryProjectLead: sameTL
      })
    }
    setAllUsers([])
  }

  const artifactoryPLChangeUser = (value) => {
    if (value) {
      let samePL = formData.jenkinsTeamLead
      if (!formData.jenkinsTeamLead && formData.jenkinsTeamLead === '') {
        samePL = `${value.name} - ${value.displayname
          ? value.displayname
          : null}`
      }
      setFormData({
        ...formData,
        artifactoryProjectLead: `${value.name} - ${value.displayname
          ? value.displayname
          : null}`,
        jenkinsTeamLead: samePL
      })
    }
    setAllUsers([])
  }

  // Handling change for Jenkins project key field
  const changeJenkinsProject = (value) => {
    if (value) {
      setAllProjects([])
      setFormData({
        ...formData,
        jenkinsReferenceProject: `${value.key} - ${value.key_app_name}`
      })
    }
  }

  // Handling change jira project key field
  const changeJiraProject = (value) => {
    if (value) {
      setAllProjects([])
      getWorkflowForOneProject(value)
    }
  }

  // Handling change for artifactory project key field
  const changeArtifactoryProject = (value) => {
    if (value) {
      setAllProjects([])
      setFormData({
        ...formData,
        artifactoryReferenceProject: `${value.key} - ${value.key_app_name}`
      })
    }
  }

  const changeqTJiraProject = (value) => {
    if (value) {
      setAllProjects([])
      setFormData({
        ...formData,
        qTreferenceProject: `${value.key} - ${value.key_app_name}`
      })
    }
  }

  const changeApplicationId = (value) => {
    if (value) {
      if (value.appid === 'Others') {
        setFormData({
          ...formData,
          qTApplicationIdValue: 'Others'
        })
      } else {
        setFormData({
          ...formData,
          qTApplicationIdValue: `${value.appid} - ${value.appname}`
        })
      }
    }
  }

  // Get workflow for one selected project
  const getWorkflowForOneProject = (value) => {
    setLoading(true)
    SupportService.getWorkflowForOneProject(value.key).then((response) => {
      setLoading(false)
      setWorkflow(response.data)
      setFormData({
        ...formData,
        jiraReferenceProject: `${value.key} - ${value.key_app_name}`,
        workflowScheme: response.data[0]
      })
    })
  }

  // get project for autocomplete
  const getProjects = (selectedTools, value) => {
    setLoadingOptions(true)
    setFormData({
      ...formData,
      searchkey: value
    })
    requestAccess.getprojects(selectedTools, value, 'all').then((response) => {
      setLoadingOptions(false)
      setAllProjects(response.data)
    })
  }

  const getAppIds = (eve) => {
    if (eve.length > 2) {
      SupportService.getApplicationId(eve).then((response) => {
        setApplicationId(response.data)
      })
    }
  }

  // Handling change for investment id field
  const changeInvestmentId = (value) => {
    if (value) {
      getSOW(value.id)
    }
  }

  // Get SOW number for selected instantis id
  const getSOW = (value) => {
    setLoading(true)
    setFormData({
      ...formData,
      searchkey: value,
      investmentID: value
    })
    SupportService.getSOW(value).then((response) => {
      setLoading(false)
      const data = [{
        SOW_NUMBER: 'None'
      }]
      response.data.map((item) => {
        data.push(item)
      })
      setAllSow(data)
    })
  }

  // Handling Submit
  const handleSubmit = (event) => {
    // setSupport({ success: false, message: 'Something went wrong. Contact system administrator!' })
    if (formData.selectedTools.includes('artifactory') &&
    formData.artifactoryProjectType.length === 0) {
      setArtifactoryError(true)
      event.preventDefault()
    } else {
      setArtifactoryError(false)
      event.preventDefault()
      submitRequest()
    }
  }

  // Submitting Request
  const submitRequest = () => {
    const date = new Date()
    const input = {
      request_for: formData.requestForName
        ? formData.requestForName
        : formData.requestFor,
      application: 'jira',
      task_srt: 'New Project Creation',
      req_det: {
        'New Project Name': formData.projectName,
        'Selected Tools': formData.selectedTools,
        'Account Owner': formData.accountOwnerDisplay,
        'Account Owner added to the project': formData
          .needAccountOwnerInProject,
        'Requested Date': date.toString(),
        'Created By': user.name.toUpperCase(),
        Comment: formData.additonalInfo.length === 0
          ? 'N/A'
          : formData.additonalInfo
      },
      sla: '5'
    }
    if (formData.investmentID !== '') {
      input.req_det['Investment ID'] = formData.investmentID
    }
    if (formData.sowNo) {
      input.req_det.SOW = formData.sowNo.SOW_NUMBER
    }
    if (formData.selectedTools.includes('jira')) {
      input.req_det.GxP = formData.gxp
    }
    if (formData.selectedTools.includes('jira')) {
      input.req_det['Jira Project Lead'] = formData.jiraProjectLead
      input
        .req_det['Have a reference of exisiting JIRA project'] = refRadioValue
      input.req_det['Enable Xray in Project'] = formData.needXray.toString()
      input.req_det['Workflow Scheme'] = formData.workflowScheme.name
      if (refRadioValue === 'Yes') {
        input.req_det['Jira - Reference Project Name'] = formData
          .jiraReferenceProject
      }
    }
    if (formData.selectedTools.includes('confluence')) {
      input.req_det['Space Admin'] = formData.confluenceSpaceAdmin
    }

    if (formData.selectedTools.includes('qtest')) {
      input.req_det['qTest Admin'] = formData.qTestAdmin
      input.req_det['qTest GxP'] = formData.gxp
      input.req_det['qTest - Jira Defect Management'] = qTesteJiraRadioValue
      if (qTesteJiraRadioValue === 'Yes') {
        input
          .req_det['qTest - Jira Defect Management Project Name'] = formData
            .qTreferenceProject
      }
      input.req_det[' qTest - Application ID'] = formData.qTApplicationIdValue
      if (formData.qTApplicationIdValue === 'Others') {
        input.req_det['qTest - Other Applicaion ID'] = formData
          .otherQTApplicationID
      }
    }
    if (formData.selectedTools.includes('bitbucket')) {
      input.req_det['Repo Admin'] = formData.repoAdmin
    }
    if (formData.selectedTools.length === 3 && (
      formData.selectedTools.includes('jenkins') &&
      formData.selectedTools.includes('artifactory') &&
      formData.selectedTools.includes('bitbucket')
    )) {
      input.req_det['Jenkins and Artifactory Team Lead'] = formData
        .jenkinsTeamLead
    }
    if (formData.selectedTools.includes('artifactory')) {
      input.req_det['Artifactory Project Lead'] = formData
        .artifactoryProjectLead
    }
    if (formData.selectedTools.includes('jenkins') ||
      formData.selectedTools.includes('artifactory')) {
      if (formData.selectedTools.includes('jenkins')) {
        input.req_det['Jenkins Team Lead'] = formData.jenkinsTeamLead
        if (!formData.selectedTools.includes('artifactory')) {
          input.req_det['Have Artifactory Project'] = formData
            .artifactoryProjectExists
        }
        if (formData.artifactoryProjectExists === true) {
          input.req_det['Artifactory Project'] = formData
            .artifactoryReferenceProject
        }

        input.req_det['Bitbucket Details'] = formData.bbProjectKey
          ? formData.bbProjectKey
          : 'None'
      } else if (formData.selectedTools.includes('artifactory')) {
        if (!formData.selectedTools.includes('jenkins')) {
          input.req_det['Have Jenkins Project'] = formData.jenkinsProjectExists
        }
        if (formData.jenkinsProjectExists === true) {
          input.req_det['Jenkins Project'] = formData.jenkinsReferenceProject
        }
      }
    }
    if (formData.selectedTools.length === 2 && (
      formData.selectedTools.includes('jenkins') &&
      formData.selectedTools.includes('artifactory')
    )) {
      input.req_det['Jenkins and Artifactory Team Lead'] = formData
        .jenkinsTeamLead
      input.req_det['Bitbucket Details'] = formData.bbProjectKey
    }
    if (formData.selectedTools.length === 2 && (
      formData.selectedTools.includes('bitbucket') &&
      (formData.selectedTools.includes('jenkins') ||
      formData.selectedTools.includes('artifactory'))
    )) {
      if (formData.selectedTools.includes('jenkins')) {
        input.req_det['Jenkins Team Lead'] = formData.jenkinsTeamLead
        if (!formData.selectedTools.includes('artifactory')) {
          input.req_det['Have Artifactory Project'] = formData.cicdProjectExists
        }
        if (formData.cicdProjectExists === true) {
          input.req_det['Artifactory Project'] = formData.referenceProject
        }
      } else {
        if (!formData.selectedTools.includes('jenkins')) {
          input.req_det['Have Jenkins Project'] = formData.cicdProjectExists
        }
        if (formData.cicdProjectExists === true) {
          input.req_det['Jenkins Project'] = formData.referenceProject
        }
      }
    }
    if (formData.selectedTools.length === 5) {
      input.req_det['Jenkins and Artifactory Team Lead'] = formData
        .jenkinsTeamLead
    }
    if (formData.selectedTools.includes('artifactory')) {
      if (showOtherPackage === true) {
        if (formData.otherArtifactoryPackage) {
          input.req_det['Other Artifactory Type'] = formData
            .otherArtifactoryPackage
        } else {
          input.req_det['Other Artifactory Type'] = 'N/A'
        }
      }
      if (formData.artifactoryProjectType.length > 0) {
        input.req_det['Artifactory Project Type'] = formData
          .artifactoryProjectType
      } else {
        input.req_det['Artifactory Project Type'] = 'NA'
      }
    }
    if (formData.selectedTools.includes('jenkins')) {
      if (formData.jenkinsProjectType.length > 0) {
        input.req_det['Jenkins Project Type'] = formData.jenkinsProjectType
      } else {
        input.req_det['Jenkins Project Type'] = 'NA'
      }
    }

    setLoading(true)
    SupportService.ticketCreation(input).then((response) => {
      setLoading(false)
      if (response.data.body.result.statusCode === 200 ||
        response.data.body.result.statusMessage === 'Success') {
        setSupport({
          success: true,
          message: `IRIS request submitted successfully
           and your request number is ${response.data.body.result
    .returnResponse.requestNumber} - ${response.data.body.result
  .returnResponse.requestedItem}`
        })
      } else {
        setSupport({
          success: false,
          message: 'Something went wrong. Contact system administrator!'
        })
      }
      setPath({
        path: '/Support',
        component: SupportComponent
      })
      props.history.push('/Support')
    }, (error) => {
      setLoading(false)
      if (error) {
        setSupport({
          success: false,
          message: 'Something went wrong. Contact system administrator!'
        })
      }
    })
  }

  /* to close the alert box */
  const handleClose = () => {
    setAlertwithok(false)
    setalertboxMessage('')
  }

  // returns the page
  return (
    <div>
      <div>
        {showAlert()}
      </div>
      <div>
        {loading ? <LinearProgress /> : null}
        {' '}
        <Grid container spacing={1} className={classes.root}>
          <Grid item xs={12} md={12}>
            <Card variant='outlined'>
              <ValidatorForm
                ref={form}
                onSubmit={(event) => handleSubmit(event)}
              >
                <CardContent>
                  <Typography
                    variant='h6'
                    gutterBottom
                    style={{
                      display: 'flow-root'
                    }}
                  >
                    Enterprise Agile Tools Project Creation
                    <IconButton
                      color='inherit'
                      aria-label='open drawer'
                      edge='end'
                      className='pull-right'
                    >
                      <a
                        href={user.project_creation}
                        target='_blank'
                        rel='noreferrer'

                      >
                        <img
                          src={helpicon}
                          height='50em'
                          width='50em'
                          alt=''
                        />
                      </a>
                    </IconButton>
                  </Typography>
                  <CardActions className={classes.pullRight}>
                    <Button
                      variant='contained'
                      component={Link}
                      to='/Support'
                      onClick={() => setPath({
                        path: '/Support',
                        component: SupportComponent
                      })}
                    >
                      Back
                    </Button>
                    <Button
                      variant='contained'
                      color='primary'
                      type='submit'
                      disabled={loading ||
                        user.switch_user ||
                        formData.selectedTools.length === 0}
                    >
                      Submit Request
                    </Button>
                  </CardActions>
                  <div style={{
                    paddingTop: 10
                  }}
                  >
                    <FormControl
                      className={`
                        ${classes.formControl} ${classes.columnDisplay}
                      `}
                    >
                      <Typography
                        htmlFor='requestForLabel'
                        variant='subtitle1'
                        display='block'
                        gutterBottom
                        className={classes.labelDisplay}
                      >
                        Request For
                        <span className={classes.error}> *</span>
                      </Typography>
                      <Autocomplete
                        id='request-for-auto-complete'
                        defaultValue={formData.requestFor}
                        className={classes.inputDisplay}
                        options={allUsers}
                        getOptionLabel={
                          (option) => (option.name
                            ? `${option.name} - ${option.displayname}`
                            : option)
                        }
                        loading={allUsers.length === 0 && loadingOptions}
                        getOptionSelected={
                          (option, userValue) => option.name === userValue.name
                        }
                        onChange={(event, userValue) => changeUser(userValue)}
                        filterOptions={(options, state) => options}
                        renderInput={(params) => (
                          <TextValidator
                            {...params}
                            name='requestFor'
                            onChange={(event) => getUsers(event.target.value)}
                            value={formData.requestFor}
                            validators={['required']}
                            errorMessages={['This field is required']}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl
                      className={`
                        ${classes.formControl} ${classes.columnDisplay}
                      `}
                    >
                      <Typography
                        htmlFor='needBitbucketLabel'
                        className={classes.labelDisplay}
                      >
                        Tools for which new project is needed.
                        <span className={classes.error}> *</span>
                      </Typography>
                      <FormGroup row>
                        <FormControlLabel
                          key='alltool'
                          value='alltool'
                          control={(
                            <Checkbox
                              name='all'
                              onChange={handleToolSelection}
                            />
                          )}
                          label='All'
                        />
                        {tools.map((tool, i) => (
                          <FormControlLabel
                            key={tool.value}
                            value={tool.value}
                            control={(
                              <Checkbox
                                name={tool}
                                disabled={allSelected}
                                checked={
                                  formData.selectedTools.includes(tool.value)
                                }
                                onChange={handleToolSelection}
                              />
                            )}
                            label={tool.name}
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                    <FormControl
                      className={`
                        ${classes.formControl} ${classes.columnDisplay}
                      `}
                    >
                      <Typography
                        htmlFor='projectNameLabel'
                        className={classes.labelDisplay}
                      >
                        {showProjectNameField()}
                        <span className={classes.error}> *</span>
                        {type === 'jenkins' ? (
                          <Tooltip
                            title={(
                              <span style={{
                                fontSize: 12,
                                fontFamily: 'Verdana'
                              }}
                              >
The free form name project or team. This is not a team key/code
                              </span>
                            )}
                          >
                            <InfoIcon
                              fontSize='small'
                              style={{
                                color: '#eea508'
                              }}
                            />
                          </Tooltip>
                        ) : null}
                      </Typography>
                      <div style={{
                        width: '72%'
                      }}
                      >
                        <TextValidator
                          name='projectName'
                          onChange={handleChange}
                          value={formData.projectName}
                          fullWidth
                          validators={['required']}
                          errorMessages={['This field is required']}
                        />
                      </div>
                    </FormControl>
                    <FormControl
                      className={`
                        ${classes.formControl} ${classes.columnDisplay}
                      `}
                    >
                      <Typography
                        htmlFor='accountOwnerLabel'
                        className={classes.labelDisplay}
                      >
                        Account Owner
                        <span className={classes.error}> *</span>
                        <Tooltip
                          title={(
                            <span style={{
                              fontSize: 12,
                              fontFamily: 'Verdana'
                            }}
                            >
User needs to be an JnJ employee. Person responsible for this project and
 owns the security groups of this project.
                            </span>
                          )}
                        >
                          <InfoIcon
                            style={{
                              color: '#eea508'
                            }}
                            fontSize='small'
                          />
                        </Tooltip>
                      </Typography>
                      <Autocomplete
                        id='request-for-auto-complete'
                        className={classes.inputDisplay}
                        options={allUsers}
                        getOptionLabel={
                          (option) => (option.name
                            ? `${option.name} - ${option.displayname}`
                            : option)
                        }
                        loading={allUsers.length === 0 && loadingOptions}
                        getOptionSelected={
                          (option, userValue) => option.name === userValue.name
                        }
                        onChange={(event, userValue) => aoChangeUser(userValue)}
                        filterOptions={(options, state) => options}
                        renderInput={(params) => (
                          <TextValidator
                            {...params}
                            name='accountOwner'
                            onChange={(event) => getUsers(event.target.value)}
                            value={formData.accountOwner}
                            validators={['required', 'checkJnJEmployee']}
                            errorMessages={
                              ['This field is required',
                                'User needs to be a JNJ employee.']
                            }
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl
                      className={`
                        ${classes.formControl} ${classes.columnDisplay}
                      `}
                    >
                      <Typography
                        htmlFor='needAccountOwnerLabel'
                        className={classes.labelDisplay}
                      >
                        Account Owner added to the project ?
                        <span className={classes.error}> *</span>
                        <Tooltip
                          title={(
                            <span style={{
                              fontSize: 12,
                              fontFamily: 'Verdana'
                            }}
                            >
If you want the account owner to have access to this project. Click Yes
                            </span>
                          )}
                        >
                          <InfoIcon
                            style={{
                              color: '#eea508'
                            }}
                            fontSize='small'
                          />
                        </Tooltip>
                      </Typography>
                      <RadioButtons
                        name='needAccountOwnerInProject'
                        className={classes.inputDisplay}
                        radio={['Yes', 'No']}
                        selectedvalue={ownerRadioValue}
                        onChange={(value) => ownerChangeOption(value)}
                      />
                    </FormControl>
                    {/*  */}
                    {/*  */}
                    {formData.selectedTools.includes('jira') ? (
                      <div>
                        <hr className={classes.dashedSeparator} />
                        <div className={classes.centerText}>
                          <span>Jira</span>
                        </div>
                        <FormControl
                          className={`
                            ${classes.formControl} ${classes.columnDisplay}
                          `}
                        >
                          <Typography
                            htmlFor='investmentIDLabel'
                            className={classes.labelDisplay}
                          >
                        Investment ID
                            {/* <span className={classes.error}> *</span> */}
                            <Tooltip
                              title={(
                                <span style={{
                                  fontSize: 12,
                                  fontFamily: 'Verdana'
                                }}
                                >
Project ID in PMT tool, Legacy Instantis ID. To get your Instantis ID
 please contact your portfolio manager to identify either the Investment or
  Base Business record that should be used.
                                </span>
                              )}
                            >
                              <InfoIcon
                                fontSize='small'
                                style={{
                                  color: '#eea508'
                                }}
                              />
                            </Tooltip>
                          </Typography>
                          <Autocomplete
                            id='investment-id-auto-complete'
                            className={classes.inputDisplay}
                            options={allInstanceId}
                            getOptionLabel={
                              (option) => (option.id
                                ? `${option.id} - ${option.name}`
                                : option)
                            }
                            loading={
                              allInstanceId.length === 0 && loadingOptions
                            }
                            getOptionSelected={
                              (option, userValue) => option.name ===
                                userValue.name
                            }
                            onChange={(event,
                              userValue) => changeInvestmentId(userValue)}
                            renderInput={(params) => (
                              <TextValidator
                                {...params}
                                name='investmentID'
                                onChange={
                                  (event) => getInstanceId(event.target.value)
                                }
                                value={formData.investmentID}
                                // validators={['required']}
                                // errorMessages={['This field is required']}
                              />
                            )}
                          />
                        </FormControl>

                        {formData.investmentID !== '' ? (
                          <FormControl
                            className={`
                              ${classes.formControl} ${classes.columnDisplay}
                            `}
                          >
                            <Typography
                              htmlFor='sowNoLabel'
                              className={classes.labelDisplay}
                            >
                          SOW Number
                              <Tooltip title={(
                                <span style={{
                                  fontSize: 12,
                                  fontFamily: 'Verdana'
                                }}
                                >
Choose None if you are not sure about the SoW number.
                                </span>
                              )}
                              >
                                <InfoIcon
                                  style={{
                                    color: '#eea508'
                                  }}
                                  fontSize='small'
                                />
                              </Tooltip>
                            </Typography>
                            <SelectValidator
                              className={classes.inputDisplay}
                              name='sowNo'
                              onChange={handleChange}
                              disabled={formData.investmentID === ''}
                            >
                              {allSow.map((value, index) => (
                                <MenuItem value={value} key={value.SOW_NUMBER}>
                                  {value.SOW_NUMBER}
                                </MenuItem>
                              ))}

                            </SelectValidator>
                          </FormControl>
                        ) : null}
                        <FormControl
                          className={`
                            ${classes.formControl} ${classes.columnDisplay}
                          `}
                        >
                          <Typography
                            htmlFor='jiraProjectLeadLabel'
                            className={classes.labelDisplay}
                          >
                            Jira Project Lead
                            <span className={classes.error}> *</span>
                            <Tooltip
                              title={(
                                <span style={{
                                  fontSize: 12,
                                  fontFamily: 'Verdana'
                                }}
                                >
Person who manages project configuration
                                </span>
                              )}
                            >
                              <InfoIcon
                                style={{
                                  color: '#eea508'
                                }}
                                fontSize='small'
                              />
                            </Tooltip>
                          </Typography>
                          <Autocomplete
                            id='request-for-auto-complete'
                            name='jiraProjectLead'
                            className={classes.inputDisplay}
                            options={allUsers}
                            getOptionLabel={
                              (option) => (option.name
                                ? `${option.name} - ${option.displayname}`
                                : option)
                            }
                            loading={allUsers.length === 0 && loadingOptions}
                            getOptionSelected={
                              (option, userValue) => option.name ===
                                userValue.name
                            }
                            value={formData.jiraProjectLead}
                            filterOptions={(options, state) => options}
                            onInputChange={(event,
                              userValue, reason) => clearText('jiraProjectLead',
                              userValue, 'reset')}
                            onChange={
                              (event, userValue) => jiraPLChangeUser(userValue)
                            }
                            renderInput={(params) => (
                              <TextValidator
                                {...params}
                                name='jiraProjectLead'
                                onChange={
                                  (event) => getUsers(event.target.value)
                                }
                                value={formData.jiraProjectLead}
                                validators={['required']}
                                errorMessages={['This field is required']}
                              />
                            )}
                          />
                        </FormControl>
                        <FormControl
                          className={`
                            ${classes.formControl} ${classes.columnDisplay}
                          `}
                        >
                          <Typography
                            htmlFor='gxpLabel'
                            className={classes.labelDisplay}
                          >
                            Jira - Is this project used for GxP activities
                            <span className={classes.error}> *</span>
                            <Tooltip
                              title={(
                                <span style={{
                                  fontSize: 12,
                                  fontFamily: 'Verdana'
                                }}
                                >
                                If you are using the project for both GxP and
                                 non-GxP then please select Yes
                                </span>
                              )}
                            >
                              <InfoIcon
                                style={{
                                  color: '#eea508'
                                }}
                                fontSize='small'
                              />
                            </Tooltip>
                          </Typography>
                          <RadioButtons
                            name='gxp'
                            className={classes.inputDisplay}
                            radio={['Yes', 'No']}
                            selectedvalue={formData.gxp}
                            onChange={handleGxpChange}
                          />
                        </FormControl>
                        <FormControl
                          className={`
                            ${classes.formControl} ${classes.columnDisplay}
                          `}
                        >
                          <Typography
                            htmlFor='existingProject'
                            className={classes.labelDisplay}
                          >
                            Reuse configuration from existing Jira project
                            <Tooltip title={(
                              <span style={{
                                fontSize: 12,
                                fontFamily: 'Verdana'
                              }}
                              >
                              If you want to use same Schemes from existing
                               project, then select Yes.
                              </span>
                            )}
                            >
                              <InfoIcon
                                style={{
                                  color: '#eea508'
                                }}
                                fontSize='small'
                              />
                            </Tooltip>
                          </Typography>
                          <RadioButtons
                            className={classes.inputDisplay}
                            radio={['Yes', 'No']}
                            selectedvalue={refRadioValue}
                            onChange={(value) => refChangeOption(value)}
                          />
                        </FormControl>
                        {refRadioValue === 'Yes' ? (
                          <FormControl
                            className={`
                              ${classes.formControl} ${classes.columnDisplay}
                            `}
                          >
                            <Typography
                              htmlFor='referenceProjectLabel'
                              className={classes.labelDisplay}
                            >
                              Reference JIRA Project
                              <span className={classes.error}> *</span>
                            </Typography>
                            <Autocomplete
                              id='reference-project-auto-complete'
                              className={classes.inputDisplay}
                              options={allProjects}
                              getOptionLabel={
                                (option) => (option.key
                                  ? `${option.key} - ${option.key_app_name}`
                                  : option)
                              }
                              loading={
                                allProjects.length === 0 && loadingOptions
                              }
                              getOptionSelected={
                                (option, userValue) => option.key ===
                                  userValue.key
                              }
                              onChange={(event,
                                userValue) => changeJiraProject(userValue)}
                              renderInput={(params) => (
                                <TextValidator
                                  {...params}
                                  name='jiraReferenceProject'
                                  onChange={
                                    (event) => getProjects(formData
                                      .selectedTools, event.target.value)
                                  }
                                  value={formData.jiraReferenceProject}
                                  validators={['required']}
                                  errorMessages={['This field is required']}
                                />
                              )}
                            />
                          </FormControl>
                        ) : null}
                        <FormControl
                          className={`
                            ${classes.formControl} ${classes.columnDisplay}
                          `}
                        >
                          <Typography
                            htmlFor='needXrayLabel'
                            className={classes.labelDisplay}
                          />
                          <FormControlLabel
                            name='needXray'
                            control={(
                              <Checkbox
                                name='needXray'
                                checked={formData.needXray}
                                onChange={handleNeedXrayChange}
                              />
                            )}
                            label='Enable Xray in Project'
                            className={classes.inputDisplay}
                          />
                        </FormControl>
                        {refRadioValue === 'Yes' ? null : (
                          <FormControl
                            className={`
                              ${classes.formControl} ${classes.columnDisplay}
                            `}
                          >
                            <Typography
                              htmlFor='workflowTypeLabel'
                              className={classes.labelDisplay}
                            >
                              Jira Workflow Type
                              <span className={classes.error}> *</span>
                            </Typography>
                            <RadioButtons
                              className={classes.inputDisplay}
                              radio={['Other', 'Consumer']}
                              selectedvalue={workflowRadioValue}
                              onChange={(value) => workflowChangeOption(value)}
                            />
                          </FormControl>
                        )}
                        <FormControl
                          className={`
                            ${classes.formControl} ${classes.columnDisplay}
                          `}
                        >
                          <Typography
                            htmlFor='workflowSchemeLabel'
                            className={classes.labelDisplay}
                          >
                            Jira Workflow Scheme
                            <span className={classes.error}> *</span>
                          </Typography>
                          <div style={{
                            width: '72%'
                          }}
                          >
                            <SelectValidator
                              name='workflowScheme'
                              fullWidth
                              required
                              // defaultValue={formData.workflowScheme[0]}
                              selectedvalue={formData.workflowScheme}
                              disabled={refRadioValue === 'Yes'}
                              value={formData.workflowScheme
                                ? formData.workflowScheme
                                : ''}
                              onChange={handleChange}
                              validators={['required']}
                              errorMessages={['This field is required']}
                            >
                              {workflow.map((value, index) => (
                                <MenuItem value={value} key={value.name}>
                                  {`${value.name} - ${value.description
                                    ? value.description
                                    : 'No description'}`}
                                </MenuItem>
                              ))}
                            </SelectValidator>
                          </div>
                        </FormControl>
                      </div>
                    ) : null}

                    {formData.selectedTools.includes('confluence') ? (
                      <div>
                        <hr className={classes.dashedSeparator} />
                        <div className={classes.centerText}>
                          <span>Confluence</span>
                        </div>
                        <FormControl
                          className={`
                            ${classes.formControl} ${classes.columnDisplay}
                          `}
                        >
                          <Typography
                            htmlFor='confluenceSpaceAdminLabel'
                            className={classes.labelDisplay}
                          >
                            Confluence Space Admin
                            <span className={classes.error}> *</span>
                            <Tooltip
                              title={(
                                <span style={{
                                  fontSize: 12,
                                  fontFamily: 'Verdana'
                                }}
                                >
                                Person who manages project configuration
                                </span>
                              )}
                            >
                              <InfoIcon
                                style={{
                                  color: '#eea508'
                                }}
                                fontSize='small'
                              />
                            </Tooltip>
                          </Typography>
                          <Autocomplete
                            id='request-for-auto-complete'
                            name='confluenceSpaceAdmin'
                            className={classes.inputDisplay}
                            options={allUsers}
                            getOptionLabel={
                              (option) => (option.name
                                ? `${option.name} - ${option.displayname}`
                                : option)
                            }
                            loading={allUsers.length === 0 && loadingOptions}
                            getOptionSelected={
                              (option, userValue) => option.name ===
                              userValue.name
                            }
                            value={formData.confluenceSpaceAdmin}
                            onInputChange={
                              (event, userValue,
                                reason) => clearText('confluenceSpaceAdmin',
                                userValue, 'reset')
                            }
                            filterOptions={(options, state) => options}
                            onChange={(event,
                              userValue) => confluenceSAChangeUser(userValue)}
                            renderInput={(params) => (
                              <TextValidator
                                {...params}
                                name='confluenceSpaceAdmin'
                                onChange={
                                  (event) => getUsers(event.target.value)
                                }
                                value={formData.confluenceSpaceAdmin}
                                validators={['required']}
                                errorMessages={['This field is required']}
                              />
                            )}
                          />
                        </FormControl>
                      </div>
                    ) : null}
                    {formData.selectedTools.includes('bitbucket') ? (
                      <div>
                        <hr className={classes.dashedSeparator} />
                        <div className={classes.centerText}>
                          <span>Bitbucket</span>
                        </div>
                        <FormControl
                          className={`
                            ${classes.formControl} ${classes.columnDisplay}
                          `}
                        >
                          <Typography
                            htmlFor='repoAdminLabel'
                            className={classes.labelDisplay}
                          >
                          Bitbucket Repo Admin
                            <span className={classes.error}> *</span>
                            <Tooltip
                              title={(
                                <span style={{
                                  fontSize: 12,
                                  fontFamily: 'Verdana'
                                }}
                                >
                              Person who would manage the repositories.
                                </span>
                              )}
                            >
                              <InfoIcon
                                style={{
                                  color: '#eea508'
                                }}
                                fontSize='small'
                              />
                            </Tooltip>
                          </Typography>
                          <Autocomplete
                            id='repo-admin-auto-complete'
                            className={classes.inputDisplay}
                            options={allUsers}
                            getOptionLabel={
                              (option) => (option.name
                                ? `${option.name} - ${option.displayname}`
                                : option)
                            }
                            loading={allUsers.length === 0 && loadingOptions}
                            getOptionSelected={
                              (option, userValue) => option.name ===
                              userValue.name
                            }
                            filterOptions={(options, state) => options}
                            onChange={(event,
                              userValue) => repoAdminChangeUser(userValue)}
                            renderInput={(params) => (
                              <TextValidator
                                {...params}
                                name='repoAdmin'
                                onChange={
                                  (event) => getUsers(event.target.value)
                                }
                                value={formData.repoAdmin}
                                validators={['required']}
                                errorMessages={['This field is required']}
                              />
                            )}
                          />
                        </FormControl>
                      </div>
                    ) : null}
                    {formData.selectedTools.includes('jenkins') ? (
                      <div>
                        <hr className={classes.dashedSeparator} />
                        <div className={classes.centerText}>
                          <span>Jenkins</span>
                        </div>
                        <FormControl
                          className={`
                            ${classes.formControl} ${classes.columnDisplay}
                          `}
                        >
                          <Typography
                            htmlFor='jenkinsTeamLeadLabel'
                            className={classes.labelDisplay}
                          >
                          Jenkins Team Lead
                            <span className={classes.error}> *</span>
                            <Tooltip
                              title={(
                                <span style={{
                                  fontSize: 12,
                                  fontFamily: 'Verdana'
                                }}
                                >
                              Person who manages project configuration
                                </span>
                              )}
                            >
                              <InfoIcon
                                style={{
                                  color: '#eea508'
                                }}
                                fontSize='small'
                              />
                            </Tooltip>
                          </Typography>
                          <Autocomplete
                            id='request-for-auto-complete'
                            className={classes.inputDisplay}
                            options={allUsers}
                            getOptionLabel={
                              (option) => (option.name
                                ? `${option.name} - ${option.displayname}`
                                : option)
                            }
                            filterOptions={(options, state) => options}
                            loading={allUsers.length === 0 && loadingOptions}
                            getOptionSelected={(option,
                              userValue) => option.name === userValue.name}
                            value={formData.jenkinsTeamLead}
                            onInputChange={(event, userValue,
                              reason) => clearText('jenkinsTeamLead',
                              userValue, 'reset')}
                            onChange={(event,
                              userValue) => jenkinsTLChangeUser(userValue)}
                            renderInput={(params) => (
                              <TextValidator
                                {...params}
                                name='jenkinsTeamLead'
                                onChange={
                                  (event) => getUsers(event.target.value)
                                }
                                value={formData.jenkinsTeamLead}
                                validators={['required']}
                                errorMessages={['This field is required']}
                              />
                            )}
                          />
                        </FormControl>
                        {!formData.selectedTools.includes('artifactory') ? (
                          <FormControl
                            className={`
                              ${classes.formControl} ${classes.columnDisplay}
                            `}
                          >
                            <Typography
                              htmlFor='artifactoryProjectExistsLabel'
                              className={classes.labelDisplay}
                            />
                            <FormControlLabel
                              name='artifactoryProjectExists'
                              control={(
                                <Checkbox
                                  name='artifactoryProjectExists'
                                  checked={formData.artifactoryProjectExists}
                                  onChange={handleCICDExists}
                                />
                              )}
                              label='Does Artifactory Project Exist ?'
                              className={classes.inputDisplay}
                            />
                          </FormControl>
                        ) : null}
                        {formData.artifactoryProjectExists === true ? (
                          <FormControl
                            className={`
                              ${classes.formControl} ${classes.columnDisplay}
                            `}
                          >
                            <Typography
                              htmlFor='referenceProjectLabel'
                              className={classes.labelDisplay}
                            >
                              Artifactory Project Key
                              <span className={classes.error}> *</span>
                              <Tooltip
                                title={(
                                  <span style={{
                                    fontSize: 12,
                                    fontFamily: 'Verdana'
                                  }}
                                  >
                                  Project key consists of 4 letters, which
                                   you can find it in your URL
                                  </span>
                                )}
                              >
                                <InfoIcon
                                  style={{
                                    color: '#eea508'
                                  }}
                                  fontSize='small'
                                />
                              </Tooltip>
                            </Typography>
                            <Autocomplete
                              id='reference-project-auto-complete'
                              className={classes.inputDisplay}
                              options={allProjects}
                              getOptionLabel={
                                (option) => (option.key
                                  ? `${option.key} - ${option.key_app_name}`
                                  : option)
                              }
                              loading={
                                allProjects.length === 0 && loadingOptions
                              }
                              getOptionSelected={(option,
                                userValue) => option.key === userValue.key}
                              onChange={
                                (event,
                                  userValue) => (
                                  changeArtifactoryProject(userValue)
                                )
                              }
                              renderInput={(params) => (
                                <TextValidator
                                  {...params}
                                  name='artifactoryReferenceProject'
                                  onChange={
                                    (event) => getProjects(formData
                                      .selectedTools, event.target.value)
                                  }
                                  value={formData.artifactoryReferenceProject}
                                  validators={['required']}
                                  errorMessages={['This field is required']}
                                />
                              )}
                            />
                          </FormControl>
                        ) : null}
                        <FormControl
                          className={`
                            ${classes.formControl} ${classes.columnDisplay}
                          `}
                        >
                          <Typography
                            htmlFor='jenkinsProjectTypeLabel'
                            className={classes.labelDisplay}
                          >
                            Jenkins Project Type
                          </Typography>
                          <FormControlLabel
                            name='jenkinsprojecttypedev'
                            style={{
                              marginLeft: '4.75%'
                            }}
                            control={(
                              <Checkbox
                                name='jenkinsprojecttypedev'
                                value='Development'
                                checked={
                                  formData
                                    .jenkinsProjectType
                                    .includes('Development')
                                }
                                onChange={(event) => handleJenkinsType(event)}
                              />
                            )}
                            label='Development'
                            className={classes.inputDisplay}
                          />
                          <FormControlLabel
                            name='jenkinsprojecttyperelease'
                            style={{
                              marginLeft: '-40%'
                            }}
                            control={(
                              <Checkbox
                                name='jenkinsprojecttyperelease'
                                value='Release'
                                checked={
                                  formData
                                    .jenkinsProjectType
                                    .includes('Release')
                                }
                                onChange={(event) => handleJenkinsType(event)}
                              />
                            )}
                            label='Release'
                            className={classes.inputDisplay}
                          />
                        </FormControl>
                        <FormControl
                          className={`
                            ${classes.formControl} ${classes.columnDisplay}
                          `}
                        >
                          <Typography
                            htmlFor='bitbucketProjectKeyLabel'
                            className={classes.labelDisplay}
                          >
                          Existing Bitbucket Project Key
                            <span className={classes.error}> *</span>
                            <Tooltip
                              title={(
                                <span style={{
                                  fontSize: 12,
                                  fontFamily: 'Verdana'
                                }}
                                >
                              Please share Bitbucket project key because
                               Jenkins job would be deployed through
                                Service Account & we have to link the
                                 service account with Jenkins project.
                                </span>
                              )}
                            >
                              <InfoIcon
                                style={{
                                  color: '#eea508'
                                }}
                                fontSize='small'
                              />
                            </Tooltip>
                          </Typography>
                          <div style={{
                            width: '72%'
                          }}
                          >
                            <TextValidator
                              name='bbProjectKey'
                              // disabled={formData.selectedTools.includes('bitbucket')}
                              onChange={handleChange}
                              value={!formData.bbProjectKey
                                ? 'None'
                                : formData.bbProjectKey}
                              fullWidth
                              validators={['required']}
                              errorMessages={['This field is required']}
                            />
                          </div>
                        </FormControl>
                      </div>
                    ) : null}
                    {formData.selectedTools.includes('artifactory') ? (
                      <div>
                        <hr className={classes.dashedSeparator} />
                        <div className={classes.centerText}>
                          <span>Artifactory</span>
                        </div>
                        <FormControl
                          className={`
                            ${classes.formControl} ${classes.columnDisplay}
                          `}
                        >
                          <Typography
                            htmlFor='artifactoryProjectLeadLabel'
                            className={classes.labelDisplay}
                          >
                          Artifactory Project Lead
                            <span className={classes.error}> *</span>
                            <Tooltip
                              title={(
                                <span style={{
                                  fontSize: 12,
                                  fontFamily: 'Verdana'
                                }}
                                >
                              Person who manages project configuration
                                </span>
                              )}
                            >
                              <InfoIcon
                                style={{
                                  color: '#eea508'
                                }}
                                fontSize='small'
                              />
                            </Tooltip>
                          </Typography>
                          <Autocomplete
                            id='request-for-auto-complete'
                            className={classes.inputDisplay}
                            options={allUsers}
                            getOptionLabel={
                              (option) => (option.name
                                ? `${option.name} - ${option.displayname}`
                                : option)
                            }
                            loading={allUsers.length === 0 && loadingOptions}
                            getOptionSelected={
                              (option, userValue) => option.name ===
                              userValue.name
                            }
                            filterOptions={(options, state) => options}
                            value={formData.artifactoryProjectLead}
                            onInputChange={(event, userValue,
                              reason) => clearText('artifactoryProjectLead',
                              userValue, 'reset')}
                            onChange={(event,
                              userValue) => artifactoryPLChangeUser(userValue)}
                            renderInput={(params) => (
                              <TextValidator
                                {...params}
                                name='artifactoryProjectLead'
                                onChange={
                                  (event) => getUsers(event.target.value)
                                }
                                value={formData.artifactoryProjectLead}
                                validators={['required']}
                                errorMessages={['This field is required']}
                              />
                            )}
                          />
                        </FormControl>
                        {!formData.selectedTools.includes('jenkins') ? (
                          <FormControl
                            className={`
                              ${classes.formControl} ${classes.columnDisplay}
                            `}
                          >
                            <Typography
                              htmlFor='jenkinsProjectExistsLabel'
                              className={classes.labelDisplay}
                            />
                            <FormControlLabel
                              name='jenkinsProjectExists'
                              control={(
                                <Checkbox
                                  name='jenkinsProjectExists'
                                  checked={formData.jenkinsProjectExists}
                                  onChange={handleCICDExists}
                                />
                              )}
                              label='Does Jenkins Project Exist ?'
                              className={classes.inputDisplay}
                            />
                          </FormControl>
                        ) : null}
                        {formData.jenkinsProjectExists === true ? (
                          <FormControl
                            className={`
                              ${classes.formControl} ${classes.columnDisplay}
                            `}
                          >
                            <Typography
                              htmlFor='referenceProjectLabel'
                              className={classes.labelDisplay}
                            >
                              Jenkins Project Key
                              <span className={classes.error}> *</span>
                              <Tooltip
                                title={(
                                  <span style={{
                                    fontSize: 12,
                                    fontFamily: 'Verdana'
                                  }}
                                  >
                                  Project key consists of 4 letters,
                                   which you can find it in your URL
                                  </span>
                                )}
                              >
                                <InfoIcon
                                  style={{
                                    color: '#eea508'
                                  }}
                                  fontSize='small'
                                />
                              </Tooltip>
                            </Typography>
                            <Autocomplete
                              id='reference-project-auto-complete'
                              className={classes.inputDisplay}
                              options={allProjects}
                              getOptionLabel={
                                (option) => (option.key
                                  ? `${option.key} - ${option.key_app_name}`
                                  : option)
                              }
                              loading={
                                allProjects.length === 0 && loadingOptions
                              }
                              getOptionSelected={
                                (option, userValue) => option.key ===
                                userValue.key
                              }
                              onChange={(event,
                                userValue) => changeJenkinsProject(userValue)}
                              renderInput={(params) => (
                                <TextValidator
                                  {...params}
                                  name='jenkinsReferenceProject'
                                  onChange={(event) => getProjects(formData
                                    .selectedTools, event.target.value)}
                                  value={formData.jenkinsReferenceProject}
                                  validators={['required']}
                                  errorMessages={['This field is required']}
                                />
                              )}
                            />
                          </FormControl>
                        ) : null}
                        <FormControl
                          className={`
                            ${classes.formControl} ${classes.columnDisplay}
                          `}
                        >
                          <Typography
                            htmlFor='jenkinsProjectTypeLabel'
                            className={classes.labelDisplay}
                          >
                            Artifactory Project Type
                            <span className={classes.error}> *</span>
                          </Typography>
                          <MultipleSelect
                            className={classes.inputDisplay}
                            handleChange={handlingArtifactoryType}
                            options={artifactoryPackages}
                            selectedValue={formData.artifactoryProjectType}
                          />
                        </FormControl>
                        {artifactoryError
                          ? (
                            <div className={classes.errorMessage}>
                              <FormHelperText className={classes.error}>
                                This field is required
                              </FormHelperText>
                            </div>
                          )
                          : null}
                        {showOtherPackage === true ? (
                          <FormControl
                            className={`
                              ${classes.formControl} ${classes.columnDisplay}
                            `}
                          >
                            <Typography
                              htmlFor='otherArtifactoryPackage'
                              className={classes.labelDisplay}
                            >
                              Other Packages
                            </Typography>
                            <TextValidator
                              name='otherArtifactoryPackage'
                              onChange={handleChange}
                              value={formData.otherArtifactoryPackage}
                              multiline
                              rows={1}
                              rowsMax={4}
                              className={classes.inputDisplay}
                            />
                          </FormControl>
                        ) : null}
                      </div>
                    ) : null}
                    {formData.selectedTools.includes('qtest') ? (
                      <div>
                        <hr className={classes.dashedSeparator} />
                        <div className={classes.centerText}>
                          <span>qTest</span>
                        </div>
                        <FormControl
                          className={`
                            ${classes.formControl} ${classes.columnDisplay}
                          `}
                        >
                          <Typography
                            htmlFor='projectLeadLabel'
                            className={classes.labelDisplay}
                          >
                            qTest Admin
                            <span className={classes.error}> *</span>
                            <Tooltip
                              title={(
                                <span style={{
                                  fontSize: 12,
                                  fontFamily: 'Verdana'
                                }}
                                >
Users responsible for release management, user management,
 data deletion and project configuration.
                                  <br />
                                  {' '}
Mandatory training - Curriculum Name: ENT-IT-TS-EAT-qTest-ProjectAdmin
                                </span>
                              )}
                            >
                              <InfoIcon
                                style={{
                                  color: '#eea508'
                                }}
                                fontSize='small'
                              />
                            </Tooltip>
                          </Typography>
                          <Autocomplete
                            id='request-for-auto-complete'
                            className={classes.inputDisplay}
                            options={allUsers}
                            getOptionLabel={
                              (option) => (option.name
                                ? `${option.name} - ${option.displayname}`
                                : option)
                            }
                            loading={allUsers.length === 0 && loadingOptions}
                            getOptionSelected={(option,
                              userValue) => option.name === userValue.name}
                            onChange={
                              (event, userValue) => qTestAdminChange(userValue)
                            }
                            filterOptions={(options, state) => options}
                            renderInput={(params) => (
                              <TextValidator
                                {...params}
                                name='qTestAdmin'
                                onChange={
                                  (event) => getUsers(event.target.value)
                                }
                                value={formData.qTestAdmin}
                                validators={['required']}
                                errorMessages={['This field is required']}
                              />
                            )}
                          />
                        </FormControl>
                        <FormControl
                          className={`
                            ${classes.formControl} ${classes.columnDisplay}
                          `}
                        >
                          <Typography
                            htmlFor='gxpLabel'
                            className={classes.labelDisplay}
                          >
                            {formData.selectedTools.includes('jira') &&
                            formData.selectedTools.includes('qtest')
                              ? 'Jira & qTest'
                              : formData.selectedTools.includes('jira')
                                ? 'Jira'
                                : formData.selectedTools.includes('qtest')
                                  ? 'qTest'
                                  : null}
                              - Is this project used for GxP activities
                            <span className={classes.error}> *</span>
                            <Tooltip
                              title={(
                                <span style={{
                                  fontSize: 12,
                                  fontFamily: 'Verdana'
                                }}
                                >
If you are using the project for both GxP and non-GxP then please select Yes
                                </span>
                              )}
                            >
                              <InfoIcon
                                style={{
                                  color: '#eea508'
                                }}
                                fontSize='small'
                              />
                            </Tooltip>
                          </Typography>
                          <RadioButtons
                            name='gxp'
                            className={classes.inputDisplay}
                            radio={['Yes', 'No']}
                            selectedvalue={formData.gxp}
                            onChange={handleGxpChange}
                          />
                        </FormControl>
                        <FormControl
                          className={`
                            ${classes.formControl} ${classes.columnDisplay}
                          `}
                        >
                          <Typography
                            htmlFor='existingProject'
                            className={classes.labelDisplay}
                          >
                            qTest - Do you have an existing Jira project
                             to track defects
                            <Tooltip title={(
                              <span style={{
                                fontSize: 12,
                                fontFamily: 'Verdana'
                              }}
                              >
qTest project can be integrated real-time with your existing Jira project.
 No new Jira project will be created.
                              </span>
                            )}
                            >
                              <InfoIcon
                                style={{
                                  color: '#eea508'
                                }}
                                fontSize='small'
                              />
                            </Tooltip>
                          </Typography>
                          <RadioButtons
                            className={classes.inputDisplay}
                            radio={['Yes', 'No']}
                            selectedvalue={qTesteJiraRadioValue}
                            onChange={(value) => qTrefChangeOption(value)}
                          />
                        </FormControl>
                        {qTesteJiraRadioValue === 'Yes' ? (
                          <FormControl
                            className={`
                              ${classes.formControl} ${classes.columnDisplay}
                            `}
                          >
                            <Typography
                              htmlFor='referenceProjectLabel'
                              className={classes.labelDisplay}
                            >
                              qTest - Jira project for Defect management
                              <span className={classes.error}> *</span>
                            </Typography>
                            <Autocomplete
                              id='reference-project-auto-complete'
                              className={classes.inputDisplay}
                              options={allProjects}
                              getOptionLabel={
                                (option) => (option.key
                                  ? `${option.key} - ${option.key_app_name}`
                                  : option)
                              }
                              loading={
                                allProjects.length === 0 && loadingOptions
                              }
                              getOptionSelected={(option,
                                userValue) => option.key === userValue.key}
                              onChange={(event,
                                userValue) => changeqTJiraProject(userValue)}
                              renderInput={(params) => (
                                <TextValidator
                                  {...params}
                                  name='referenceProject'
                                  onChange={(event) => getProjects('jira',
                                    event.target.value)}
                                  value={formData.qTreferenceProject}
                                  validators={['required']}
                                  errorMessages={['This field is required']}
                                />
                              )}
                            />
                          </FormControl>
                        ) : null}
                        <FormControl
                          className={`
                            ${classes.formControl} ${classes.columnDisplay}
                          `}
                        >
                          <Typography
                            htmlFor='referenceProjectLabel'
                            className={classes.labelDisplay}
                          >
                              qTest - Application ID
                            <span className={classes.error}> *</span>
                            <Tooltip title={(
                              <span style={{
                                fontSize: 12,
                                fontFamily: 'Verdana'
                              }}
                              >
                                  A unique ID that is system-generated for each
                                   Application CI (Configuration Item) and
                                    will be stored in CMDB. This will be
                                     available for your application in
                                      IRIS - CMDB
                              </span>
                            )}
                            >
                              <InfoIcon
                                style={{
                                  color: '#eea508'
                                }}
                                fontSize='small'
                              />
                            </Tooltip>
                          </Typography>
                          <Autocomplete
                            id='reference-project-auto-complete'
                            className={classes.inputDisplayQTId}
                            disabled={qTestAppIdNotListedDisable}
                            options={applicationId}
                            getOptionLabel={
                              (option) => (option.appid
                                ? `${option.appid} - ${option.appname}`
                                : option)
                            }
                            loading={applicationId.length === 0 &&
                              loadingOptions}
                            getOptionSelected={(option,
                              userValue) => option.appid === userValue.appid}
                            onChange={(event,
                              userValue) => changeApplicationId(userValue)}
                            renderInput={(params) => (
                              <TextValidator
                                {...params}
                                name='referenceProject'
                                onChange={
                                  (event) => getAppIds(event.target.value)
                                }
                                value={formData.qTApplicationIdValue}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                disabled={qTestAppIdNotListedDisable}
                              />
                            )}
                          />
                          <FormControlLabel
                            name='notListedApplicationID'
                            control={(
                              <Checkbox
                                name='needXray'
                                checked={formData.notListedApplicationID}
                                onChange={handleQTNotListedAppId}
                              />
                            )}
                            label='Application ID Not Available / Not Listed'
                            className={classes.inputDisplayQTOId}
                          />
                        </FormControl>
                        {formData.qTApplicationIdValue === 'Others' ? (
                          <FormControl
                            className={`
                              ${classes.formControl} ${classes.columnDisplay}
                            `}
                          >
                            <Typography
                              htmlFor='otherQTApplicationIDLabel'
                              className={classes.labelDisplay}
                            >
                        qTest - Default Application ID
                              <Tooltip title={(
                                <span style={{
                                  fontSize: 12,
                                  fontFamily: 'Verdana'
                                }}
                                >
                                  Default Application ID is displayed in this
                                   field.
                                  If you have an application ID but not listed
                                   in the drop down then mention the
                                  application ID in Additional information field
                                </span>
                              )}
                              >
                                <InfoIcon
                                  style={{
                                    color: '#eea508'
                                  }}
                                  fontSize='small'
                                />
                              </Tooltip>
                            </Typography>
                            <div style={{
                              width: '72%'
                            }}
                            >
                              <TextValidator
                                name='otherQTApplicationID'
                                onChange={handleChange}
                                value={formData.otherQTApplicationID}
                                disabled={false}
                                fullWidth
                              />
                            </div>
                          </FormControl>
                        ) : null}
                      </div>
                    ) : null}
                    <hr className={classes.dashedSeparator} />

                    <FormControl
                      className={`
                        ${classes.formControl} ${classes.columnDisplay}
                      `}
                    >
                      <Typography
                        htmlFor='additonalInfoLabel'
                        className={classes.labelDisplay}
                      >
                        Additional Information
                      </Typography>
                      <div style={{
                        width: '72%'
                      }}
                      >
                        <TextValidator
                          name='additonalInfo'
                          onChange={handleChange}
                          value={formData.additonalInfo}
                          multiline
                          rows={1}
                          rowsMax={4}
                          fullWidth
                        />
                      </div>
                    </FormControl>
                  </div>
                </CardContent>
              </ValidatorForm>
            </Card>
            <AlertDialog
              handleClose={handleClose}
              alertopen={alertwithok}
              key='alert'
              message={alertboxMessage}
              okbuttonalert
              title='Alert'
            />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}